/* ===  =================================== _____________ footer Styles _____________ ====================================== */
@use '../../../styles/sass/utilities/variables' as vars;
@use '../../../styles/sass/utilities/mixins' as mix;

.footer {
   background-color: vars.$mainColor;
   padding: 3.5rem 0 1.5rem;

   &_logo {

      img {
         width: 120px;
      }
   }

   &_col {

      &_title {
         font-size: vars.$font-1100;
         color: white;
         margin-bottom: 2rem;
         font-weight: bold;

         @include mix.respond(medium2) {
            margin-bottom: 1.5rem;
         }
      }

      &_list {
         

         &_item {
            font-size: vars.$font-1000;
            color: white;
            margin-bottom: 1rem;
            position: relative;
            padding-inline-start: 1.2rem;
            font-weight: 500;
            font-family: "Helvetica LT";
            &:before {
               position: absolute;
               left: 0;
               top: 50%;
               transform: translateY(-50%);
               content: "";
               width: 19px;
               height: 19px;
               background-size: contain;
               background-repeat: no-repeat;
               background-image: url("../../../../public/Icon.png");

               body.ar & {
                  left: auto;
                  right: 0;
               }
            }
         }
      }

      &_social {
         margin-bottom: 0;
         margin-top: 2rem;
         gap: 20px;
         display: flex;

         &_item {
            &_link {
               svg {
                  font-size: 1.75rem;
                  color: white;
               }
            }
         }

      }
   }

   &_copyright {
      padding-top: 1.5rem;

      @include mix.respond(medium2) {
         padding-top: 3rem
      }

      &_rightParagraph {
         text-align: end;


         img {
            width: 55px;
            padding-inline-start: 5px;
         }
      }

      &_leftParagraph,
      &_rightParagraph {
         color: vars.$white;
         font-size: vars.$font-800;
         margin-bottom: 0;
         font-weight: lighter;
         font-family: "Helvetica LT";

         @include mix.respond(landscape2) {
            text-align: center;
         }
      }

      &_leftParagraph {
         @include mix.respond(landscape2) {
            margin-bottom: 1rem;
         }
      }

   }

}

.footerAr {
   ul {
      padding-right: 0;
      li {
         &::before {
               left: auto;
               right: 0;
               transform: translateY(-50%) rotate(180deg);
         }
      }
   }
   
}

/* ===  =================================== _____________ footer Styles _____________ ====================================== */