/* ====================================== _____________ halfSection Styles _____________ ====================================== */
@use '../../styles/sass/utilities/variables' as vars;
@use '../../styles/sass/utilities/mixins' as mix;

.halfSection {
   background-color: vars.$mainBgColor;
   overflow: hidden;

   &_content {
      padding: 2.75rem 3.5rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include mix.respond(medium2) {
         padding: 2rem 3rem;
      }

      @include mix.respond(landscape2) {
         padding: 2rem 2rem;
      }

      .halfSection_imgContainer_logos {
         display: block;
         text-align: start;
      }

      &_desc {
         color: vars.$textColor;
         font-size: vars.$font-1000;
         margin-bottom: 0;
      }

      &_link {
         margin-top: 1rem;
         text-decoration: underline;
         color: vars.$mainColor;

         font-weight: 700;

         &:hover {
            text-decoration: underline;
            color: vars.$mainColor;
         }
      }
   }

   &_imgContainer {
      height: 100%;

      &_logos {
         text-align: center;
         margin-bottom: 1rem;
         display: flex;
         align-items: center;
         justify-content: center;
         gap: 30px;
      }

      &_img {
         min-height: 100%;
         width: 100%;
         object-fit: cover;
         min-height: auto;
         @media (max-width: 991px) {
            height: auto;
         }
      }
   }
  

   &_cust {
      .halfSection_imgContainer_logos {
         display: none;
      }
   }
 
   

  

}



/* ====================================== _____________ halfSection Styles _____________ ====================================== */