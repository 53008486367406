/* ===  =================================== _____________ sidebar Styles _____________ ====================================== */
@use '../../../styles/sass/utilities/variables' as vars;
@use '../../../styles/sass/utilities/mixins' as mix;

.sidebar {
   width: 100%;
   background-color: vars.$mainColor;
   margin: 10px auto;
   padding: 80px 0;
   position: fixed;
   left: 0;
   right: 0;
   z-index: 5;
   height: calc(100vh - 96px);
   top: 87px;
   overflow: hidden;
   @media (max-width: 991px) {
      overflow-x: hidden;
      overflow-y: scroll;
   }
   &_exit {
      color: white;
      position: absolute;
      right: 40px;
      top: 25px;
      font-size: 1.25rem;
      cursor: pointer;
      border: 1px solid white;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      transition: vars.$transition;

      &:hover {
         color: vars.$mainColor;
         background-color: white;
      }

      @media (max-width: 1200px) {
         right: 38px;
         top: 23px;
         font-size: 1.2rem;
         width: 38px;
         height: 38px;
         line-height: 38px;
      }

      @include mix.respond(medium2) {
         right: 35px;
         top: 20px;
         font-size: 1rem;
         width: 35px;
         height: 35px;
         line-height: 35px;
      }

      @include mix.respond(small) {
         right: 25px;
         top: 20px;
         font-size: 1rem;
         width: 35px;
         height: 35px;
         line-height: 35px;
      }
   }

   &_content {
      @include mix.respond(landscape2) {
         display: flex;
         flex-direction: column;
         justify-content: center;
         height: 100%;
      }

      &_title {
         color: white;
         font-size: vars.$font-1800;
         font-weight: 400;
         margin-bottom: 1.5rem;
         text-transform: uppercase;
         width: 80%;
         @media (max-width: 768px) {
            width: 100%;
         }
      }

      &_desc {
         color: white;
         font-weight: 500;
         font-size: vars.$font-1000;
         opacity: 0.8;
         width: 78%;
         margin-bottom: 0;
      }
   }

   &_links {
      margin-inline-start: 2rem;
      margin-bottom: 0;
      border-inline-start: 0.25px solid white;

      @include mix.respond(medium2) {
         margin-inline-start: 0;
      }

      &_item {
         color: white;
         padding-bottom: 1rem;
         padding-inline-start: 1rem;
         position: relative;
         z-index: 9;
         &:last-child {
            padding-bottom: 0;
         }
         &_dropdown {
            &_title {
               color: white;
               display: block;
               font-size: vars.$font-850;
               font-weight: bold;
               padding-bottom: 0.5rem;
               margin-top: 1rem;
               margin-bottom: 0.5rem;

               margin-inline-start: 1.5rem;
               text-transform: capitalize;
               width: max-content;
            }
         }
      }
     
   }

   &_img {
      border-radius: 10px;
      // height: 100% !important;
      width: 95%;
      object-fit: cover;
      position: relative;
      z-index: 9;
   }
   &_pattern {
      @keyframes moveImage {
         0% {
           transform: translateX(-75px) rotateX(0);
         }
         50% {
           transform: translateX(-250px) rotateX(180deg);
         }
         100% {
           transform: translateX(-75px) rotateX(0);
         }
       }
      animation: moveImage 30s ease-in-out infinite;
      width: 200%;
      position: absolute;
      bottom: 0;
      left: 0;
   }
   @media (max-width: 1200px) {
      height: calc(100vh - 95px);
        top: 88px;
   }

}

/* ===  =================================== _____________ sidebar Styles _____________ ====================================== */