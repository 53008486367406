/* ===  =================================== _____________ secondCard Styles _____________ ====================================== */
@use '../../../styles/sass/utilities/variables' as vars;
@use '../../../styles/sass/utilities/mixins' as mix;

.step {
   padding: 1.5rem;
   font-family: "D-DIN Condensed";
   height: 100%;
   &.darkRed {
      background-color: #C31F33;
   }
   &.red {
      background-color: #EB1C2D;
   }
   &.yellow {
      background-color: #D3A029;
   }
   &.green {
      background-color: #279B48;
   }
   &_number {
      font-weight: bold;
      color: white;
      font-size: 3.5rem;
      margin-bottom: 0;
   }
   &_title {
      font-size: 2.2rem;
      color: white;
      margin-bottom: 0;
      font-weight: bold;
      padding-inline-start: 1rem;
   }
   &_img {
      width: 100%;
   }
}



/* ===  =================================== _____________ secondCard Styles _____________ ====================================== */