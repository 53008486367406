/* ====================================== _____________ Fonts Styles _____________ ====================================== */
@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('../../fonts/HelveticaNeueLTStd-Lt.eot');
    src: url('../../fonts/HelveticaNeueLTStd-Lt.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTStd-Lt.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTStd-Lt.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTStd-Lt.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTStd-Lt.svg#HelveticaNeueLTStd-Lt') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('../../fonts/HelveticaNeueLTStd-UltLt.eot');
    src: url('../../fonts/HelveticaNeueLTStd-UltLt.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTStd-UltLt.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTStd-UltLt.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTStd-UltLt.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTStd-UltLt.svg#HelveticaNeueLTStd-UltLt') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}





@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('../../fonts/HelveticaNeueLTStd-UltLtCn.eot');
    src: url('../../fonts/HelveticaNeueLTStd-UltLtCn.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTStd-UltLtCn.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTStd-UltLtCn.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTStd-UltLtCn.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTStd-UltLtCn.svg#HelveticaNeueLTStd-UltLtCn') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('../../fonts/HelveticaNeueLTStd-Md.eot');
    src: url('../../fonts/HelveticaNeueLTStd-Md.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTStd-Md.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTStd-Md.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTStd-Md.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTStd-Md.svg#HelveticaNeueLTStd-Md') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('../../fonts/HelveticaNeueLTStd-Cn.eot');
    src: url('../../fonts/HelveticaNeueLTStd-Cn.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTStd-Cn.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTStd-Cn.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTStd-Cn.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTStd-Cn.svg#HelveticaNeueLTStd-Cn') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'BMW Helvetica';
    src: url('../../fonts/BMWHelvetica-Bold.eot');
    src: url('../../fonts/BMWHelvetica-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/BMWHelvetica-Bold.woff2') format('woff2'),
        url('../../fonts/BMWHelvetica-Bold.woff') format('woff'),
        url('../../fonts/BMWHelvetica-Bold.ttf') format('truetype'),
        url('../../fonts/BMWHelvetica-Bold.svg#BMWHelvetica-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('../../fonts/HelveticaNeueLTStd-LtEx.eot');
    src: url('../../fonts/HelveticaNeueLTStd-LtEx.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTStd-LtEx.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTStd-LtEx.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTStd-LtEx.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTStd-LtEx.svg#HelveticaNeueLTStd-LtEx') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('../../fonts/HelveticaNeueLTStd-Roman.eot');
    src: url('../../fonts/HelveticaNeueLTStd-Roman.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTStd-Roman.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTStd-Roman.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTStd-Roman.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTStd-Roman.svg#HelveticaNeueLTStd-Roman') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('../../fonts/HelveticaNeueLTStd-ThCn.eot');
    src: url('../../fonts/HelveticaNeueLTStd-ThCn.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTStd-ThCn.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTStd-ThCn.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTStd-ThCn.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTStd-ThCn.svg#HelveticaNeueLTStd-ThCn') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('../../fonts/HelveticaNeueLTStd-MdEx.eot');
    src: url('../../fonts/HelveticaNeueLTStd-MdEx.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTStd-MdEx.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTStd-MdEx.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTStd-MdEx.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTStd-MdEx.svg#HelveticaNeueLTStd-MdEx') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('../../fonts/HelveticaNeueLTStd-Th.eot');
    src: url('../../fonts/HelveticaNeueLTStd-Th.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTStd-Th.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTStd-Th.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTStd-Th.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTStd-Th.svg#HelveticaNeueLTStd-Th') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('../../fonts/HelveticaNeueLTStd-Ex.eot');
    src: url('../../fonts/HelveticaNeueLTStd-Ex.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTStd-Ex.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTStd-Ex.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTStd-Ex.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTStd-Ex.svg#HelveticaNeueLTStd-Ex') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}




@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('../../fonts/HelveticaNeueLTStd-ThEx.eot');
    src: url('../../fonts/HelveticaNeueLTStd-ThEx.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTStd-ThEx.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTStd-ThEx.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTStd-ThEx.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTStd-ThEx.svg#HelveticaNeueLTStd-ThEx') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('../../fonts/HelveticaNeueLTStd-LtCn.eot');
    src: url('../../fonts/HelveticaNeueLTStd-LtCn.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTStd-LtCn.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTStd-LtCn.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTStd-LtCn.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTStd-LtCn.svg#HelveticaNeueLTStd-LtCn') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('../../fonts/HelveticaNeueLTStd-UltLtEx.eot');
    src: url('../../fonts/HelveticaNeueLTStd-UltLtEx.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTStd-UltLtEx.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTStd-UltLtEx.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTStd-UltLtEx.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTStd-UltLtEx.svg#HelveticaNeueLTStd-UltLtEx') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('../../fonts/HelveticaNeueLTStd-BdOu.eot');
    src: url('../../fonts/HelveticaNeueLTStd-BdOu.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTStd-BdOu.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTStd-BdOu.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTStd-BdOu.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTStd-BdOu.svg#HelveticaNeueLTStd-BdOu') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('../../fonts/HelveticaNeueLTStd-BdEx.eot');
    src: url('../../fonts/HelveticaNeueLTStd-BdEx.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTStd-BdEx.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTStd-BdEx.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTStd-BdEx.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTStd-BdEx.svg#HelveticaNeueLTStd-BdEx') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('../../fonts/HelveticaNeueLTStd-BlkEx.eot');
    src: url('../../fonts/HelveticaNeueLTStd-BlkEx.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTStd-BlkEx.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTStd-BlkEx.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTStd-BlkEx.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTStd-BlkEx.svg#HelveticaNeueLTStd-BlkEx') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('../../fonts/HelveticaNeueLTStd-MdCn.eot');
    src: url('../../fonts/HelveticaNeueLTStd-MdCn.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTStd-MdCn.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTStd-MdCn.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTStd-MdCn.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTStd-MdCn.svg#HelveticaNeueLTStd-MdCn') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica LT';
    src: url('../../fonts/HelveticaLT-Light.eot');
    src: url('../../fonts/HelveticaLT-Light.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaLT-Light.woff2') format('woff2'),
        url('../../fonts/HelveticaLT-Light.woff') format('woff'),
        url('../../fonts/HelveticaLT-Light.ttf') format('truetype'),
        url('../../fonts/HelveticaLT-Light.svg#HelveticaLT-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica LT CondensedLight';
    src: url('../../fonts/HelveticaLT-Condensed-Light.eot');
    src: url('../../fonts/HelveticaLT-Condensed-Light.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaLT-Condensed-Light.woff2') format('woff2'),
        url('../../fonts/HelveticaLT-Condensed-Light.woff') format('woff'),
        url('../../fonts/HelveticaLT-Condensed-Light.ttf') format('truetype'),
        url('../../fonts/HelveticaLT-Condensed-Light.svg#HelveticaLT-Condensed-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica LT ExtraCompressed';
    src: url('../../fonts/HelveticaLT-ExtraCompressed.eot');
    src: url('../../fonts/HelveticaLT-ExtraCompressed.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaLT-ExtraCompressed.woff2') format('woff2'),
        url('../../fonts/HelveticaLT-ExtraCompressed.woff') format('woff'),
        url('../../fonts/HelveticaLT-ExtraCompressed.ttf') format('truetype'),
        url('../../fonts/HelveticaLT-ExtraCompressed.svg#HelveticaLT-ExtraCompressed') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica LT Narrow';
    src: url('../../fonts/HelveticaLT-Narrow-Bold.eot');
    src: url('../../fonts/HelveticaLT-Narrow-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaLT-Narrow-Bold.woff2') format('woff2'),
        url('../../fonts/HelveticaLT-Narrow-Bold.woff') format('woff'),
        url('../../fonts/HelveticaLT-Narrow-Bold.ttf') format('truetype'),
        url('../../fonts/HelveticaLT-Narrow-Bold.svg#HelveticaLT-Narrow-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Helvetica LT Narrow';
    src: url('../../fonts/HelveticaLT-Narrow.eot');
    src: url('../../fonts/HelveticaLT-Narrow.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaLT-Narrow.woff2') format('woff2'),
        url('../../fonts/HelveticaLT-Narrow.woff') format('woff'),
        url('../../fonts/HelveticaLT-Narrow.ttf') format('truetype'),
        url('../../fonts/HelveticaLT-Narrow.svg#HelveticaLT-Narrow') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('../../fonts/HelveticaNeueLTStd-Blk.eot');
    src: url('../../fonts/HelveticaNeueLTStd-Blk.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTStd-Blk.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTStd-Blk.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTStd-Blk.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTStd-Blk.svg#HelveticaNeueLTStd-Blk') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('../../fonts/HelveticaNeueLTStd-BlkCn.eot');
    src: url('../../fonts/HelveticaNeueLTStd-BlkCn.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTStd-BlkCn.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTStd-BlkCn.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTStd-BlkCn.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTStd-BlkCn.svg#HelveticaNeueLTStd-BlkCn') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeueW01-66MediumIt';
    src: url('../../fonts/HelveticaNeueW01-66MediumIt.eot');
    src: url('../../fonts/HelveticaNeueW01-66MediumIt.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueW01-66MediumIt.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueW01-66MediumIt.woff') format('woff'),
        url('../../fonts/HelveticaNeueW01-66MediumIt.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueW01-66MediumIt.svg#HelveticaNeueW01-66MediumIt') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std';
    src: url('../../fonts/HelveticaNeueLTStd-XBlkCn.eot');
    src: url('../../fonts/HelveticaNeueLTStd-XBlkCn.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTStd-XBlkCn.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTStd-XBlkCn.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTStd-XBlkCn.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTStd-XBlkCn.svg#HelveticaNeueLTStd-XBlkCn') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Helvetica Neue';
    src: url('../../fonts/HelveticaNeue-UltraLight.eot');
    src: url('../../fonts/HelveticaNeue-UltraLight.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeue-UltraLight.woff2') format('woff2'),
        url('../../fonts/HelveticaNeue-UltraLight.woff') format('woff'),
        url('../../fonts/HelveticaNeue-UltraLight.ttf') format('truetype'),
        url('../../fonts/HelveticaNeue-UltraLight.svg#HelveticaNeue-UltraLight') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeueW01-77BdCnObl';
    src: url('../../fonts/HelveticaNeueW01-77BdCnObl.eot');
    src: url('../../fonts/HelveticaNeueW01-77BdCnObl.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueW01-77BdCnObl.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueW01-77BdCnObl.woff') format('woff'),
        url('../../fonts/HelveticaNeueW01-77BdCnObl.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueW01-77BdCnObl.svg#HelveticaNeueW01-77BdCnObl') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeueW01-87HvCnObl';
    src: url('../../fonts/HelveticaNeueW01-87HvCnObl.eot');
    src: url('../../fonts/HelveticaNeueW01-87HvCnObl.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueW01-87HvCnObl.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueW01-87HvCnObl.woff') format('woff'),
        url('../../fonts/HelveticaNeueW01-87HvCnObl.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueW01-87HvCnObl.svg#HelveticaNeueW01-87HvCnObl') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url('../../fonts/Helvetica-Light.eot');
    src: url('../../fonts/Helvetica-Light.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/Helvetica-Light.woff2') format('woff2'),
        url('../../fonts/Helvetica-Light.woff') format('woff'),
        url('../../fonts/Helvetica-Light.ttf') format('truetype'),
        url('../../fonts/Helvetica-Light.svg#Helvetica-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url('../../fonts/Helvetica-Compressed.eot');
    src: url('../../fonts/Helvetica-Compressed.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/Helvetica-Compressed.woff2') format('woff2'),
        url('../../fonts/Helvetica-Compressed.woff') format('woff'),
        url('../../fonts/Helvetica-Compressed.ttf') format('truetype'),
        url('../../fonts/Helvetica-Compressed.svg#Helvetica-Compressed') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaLT';
    src: url('../../fonts/HelveticaLT.eot');
    src: url('../../fonts/HelveticaLT.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaLT.woff2') format('woff2'),
        url('../../fonts/HelveticaLT.woff') format('woff'),
        url('../../fonts/HelveticaLT.ttf') format('truetype'),
        url('../../fonts/HelveticaLT.svg#HelveticaLT') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url('../../fonts/Helvetica-Bold.eot');
    src: url('../../fonts/Helvetica-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/Helvetica-Bold.woff2') format('woff2'),
        url('../../fonts/Helvetica-Bold.woff') format('woff'),
        url('../../fonts/Helvetica-Bold.ttf') format('truetype'),
        url('../../fonts/Helvetica-Bold.svg#Helvetica-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Helvetica LT CondensedBlack';
    src: url('../../fonts/HelveticaLT-Condensed-Black.eot');
    src: url('../../fonts/HelveticaLT-Condensed-Black.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaLT-Condensed-Black.woff2') format('woff2'),
        url('../../fonts/HelveticaLT-Condensed-Black.woff') format('woff'),
        url('../../fonts/HelveticaLT-Condensed-Black.ttf') format('truetype'),
        url('../../fonts/HelveticaLT-Condensed-Black.svg#HelveticaLT-Condensed-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica LT Condensed';
    src: url('../../fonts/HelveticaLT-Condensed.eot');
    src: url('../../fonts/HelveticaLT-Condensed.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaLT-Condensed.woff2') format('woff2'),
        url('../../fonts/HelveticaLT-Condensed.woff') format('woff'),
        url('../../fonts/HelveticaLT-Condensed.ttf') format('truetype'),
        url('../../fonts/HelveticaLT-Condensed.svg#HelveticaLT-Condensed') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url('../../fonts/Helvetica.eot');
    src: url('../../fonts/Helvetica.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/Helvetica.woff2') format('woff2'),
        url('../../fonts/Helvetica.woff') format('woff'),
        url('../../fonts/Helvetica.ttf') format('truetype'),
        url('../../fonts/Helvetica.svg#Helvetica') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica LT Condensed';
    src: url('../../fonts/HelveticaLT-Condensed-Bold.eot');
    src: url('../../fonts/HelveticaLT-Condensed-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaLT-Condensed-Bold.woff2') format('woff2'),
        url('../../fonts/HelveticaLT-Condensed-Bold.woff') format('woff'),
        url('../../fonts/HelveticaLT-Condensed-Bold.ttf') format('truetype'),
        url('../../fonts/HelveticaLT-Condensed-Bold.svg#HelveticaLT-Condensed-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('../../fonts/HelveticaNeueLTPro-Th.eot');
    src: url('../../fonts/HelveticaNeueLTPro-Th.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTPro-Th.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTPro-Th.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTPro-Th.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTPro-Th.svg#HelveticaNeueLTPro-Th') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('../../fonts/HelveticaNeueBlackCond.eot');
    src: url('../../fonts/HelveticaNeueBlackCond.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueBlackCond.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueBlackCond.woff') format('woff'),
        url('../../fonts/HelveticaNeueBlackCond.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueBlackCond.svg#HelveticaNeueBlackCond') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'Helvetica LT UltraCompressed';
    src: url('../../fonts/HelveticaLT-UltraCompressed.eot');
    src: url('../../fonts/HelveticaLT-UltraCompressed.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaLT-UltraCompressed.woff2') format('woff2'),
        url('../../fonts/HelveticaLT-UltraCompressed.woff') format('woff'),
        url('../../fonts/HelveticaLT-UltraCompressed.ttf') format('truetype'),
        url('../../fonts/HelveticaLT-UltraCompressed.svg#HelveticaLT-UltraCompressed') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeueLT Arabic 45';
    src: url('../../fonts/HelveticaNeueLTArabic-Light.eot');
    src: url('../../fonts/HelveticaNeueLTArabic-Light.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTArabic-Light.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTArabic-Light.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTArabic-Light.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTArabic-Light.svg#HelveticaNeueLTArabic-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeueLT Arabic 75';
    src: url('../../fonts/HelveticaNeueLTArabic-Bold.eot');
    src: url('../../fonts/HelveticaNeueLTArabic-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTArabic-Bold.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTArabic-Bold.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTArabic-Bold.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTArabic-Bold.svg#HelveticaNeueLTArabic-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeueLT Arabic 55 Roman';
    src: url('../../fonts/HelveticaNeueLTArabic-Roman.eot');
    src: url('../../fonts/HelveticaNeueLTArabic-Roman.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTArabic-Roman.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTArabic-Roman.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTArabic-Roman.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTArabic-Roman.svg#HelveticaNeueLTArabic-Roman') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('../../fonts/HelveticaNeueLTPro-LtEx.eot');
    src: url('../../fonts/HelveticaNeueLTPro-LtEx.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTPro-LtEx.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTPro-LtEx.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTPro-LtEx.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTPro-LtEx.svg#HelveticaNeueLTPro-LtEx') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('../../fonts/HelveticaNeueLTPro-Ex.eot');
    src: url('../../fonts/HelveticaNeueLTPro-Ex.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTPro-Ex.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTPro-Ex.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTPro-Ex.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTPro-Ex.svg#HelveticaNeueLTPro-Ex') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('../../fonts/HelveticaNeueLTPro-Cn.eot');
    src: url('../../fonts/HelveticaNeueLTPro-Cn.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTPro-Cn.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTPro-Cn.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTPro-Cn.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTPro-Cn.svg#HelveticaNeueLTPro-Cn') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('../../fonts/HelveticaNeueLTPro-Md.eot');
    src: url('../../fonts/HelveticaNeueLTPro-Md.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTPro-Md.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTPro-Md.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTPro-Md.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTPro-Md.svg#HelveticaNeueLTPro-Md') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('../../fonts/HelveticaNeueLTPro-BlkCn.eot');
    src: url('../../fonts/HelveticaNeueLTPro-BlkCn.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTPro-BlkCn.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTPro-BlkCn.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTPro-BlkCn.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTPro-BlkCn.svg#HelveticaNeueLTPro-BlkCn') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('../../fonts/HelveticaNeueLTPro-BdOu.eot');
    src: url('../../fonts/HelveticaNeueLTPro-BdOu.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTPro-BdOu.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTPro-BdOu.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTPro-BdOu.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTPro-BdOu.svg#HelveticaNeueLTPro-BdOu') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('../../fonts/HelveticaNeueLTPro-MdEx.eot');
    src: url('../../fonts/HelveticaNeueLTPro-MdEx.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTPro-MdEx.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTPro-MdEx.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTPro-MdEx.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTPro-MdEx.svg#HelveticaNeueLTPro-MdEx') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('../../fonts/HelveticaNeueLTPro-LtCn.eot');
    src: url('../../fonts/HelveticaNeueLTPro-LtCn.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTPro-LtCn.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTPro-LtCn.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTPro-LtCn.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTPro-LtCn.svg#HelveticaNeueLTPro-LtCn') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('../../fonts/HelveticaNeueLTPro-MdCn.eot');
    src: url('../../fonts/HelveticaNeueLTPro-MdCn.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTPro-MdCn.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTPro-MdCn.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTPro-MdCn.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTPro-MdCn.svg#HelveticaNeueLTPro-MdCn') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('../../fonts/HelveticaNeueLTPro-Lt.eot');
    src: url('../../fonts/HelveticaNeueLTPro-Lt.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTPro-Lt.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTPro-Lt.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTPro-Lt.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTPro-Lt.svg#HelveticaNeueLTPro-Lt') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('../../fonts/HelveticaNeueLTPro-BlkEx.eot');
    src: url('../../fonts/HelveticaNeueLTPro-BlkEx.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTPro-BlkEx.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTPro-BlkEx.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTPro-BlkEx.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTPro-BlkEx.svg#HelveticaNeueLTPro-BlkEx') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('../../fonts/HelveticaNeueLTPro-Roman.eot');
    src: url('../../fonts/HelveticaNeueLTPro-Roman.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTPro-Roman.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTPro-Roman.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTPro-Roman.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTPro-Roman.svg#HelveticaNeueLTPro-Roman') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('../../fonts/HelveticaNeueLTPro-Blk.eot');
    src: url('../../fonts/HelveticaNeueLTPro-Blk.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTPro-Blk.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTPro-Blk.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTPro-Blk.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTPro-Blk.svg#HelveticaNeueLTPro-Blk') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}




@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('../../fonts/HelveticaNeueLTPro-ThEx.eot');
    src: url('../../fonts/HelveticaNeueLTPro-ThEx.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTPro-ThEx.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTPro-ThEx.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTPro-ThEx.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTPro-ThEx.svg#HelveticaNeueLTPro-ThEx') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('../../fonts/HelveticaNeueLTPro-UltLtEx.eot');
    src: url('../../fonts/HelveticaNeueLTPro-UltLtEx.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTPro-UltLtEx.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTPro-UltLtEx.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTPro-UltLtEx.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTPro-UltLtEx.svg#HelveticaNeueLTPro-UltLtEx') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'Helvetica';
    src: url('../../fonts/HelveticaRounded-Bold.eot');
    src: url('../../fonts/HelveticaRounded-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaRounded-Bold.woff2') format('woff2'),
        url('../../fonts/HelveticaRounded-Bold.woff') format('woff'),
        url('../../fonts/HelveticaRounded-Bold.ttf') format('truetype'),
        url('../../fonts/HelveticaRounded-Bold.svg#HelveticaRounded-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('../../fonts/HelveticaNeueLTPro-UltLtCn.eot');
    src: url('../../fonts/HelveticaNeueLTPro-UltLtCn.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTPro-UltLtCn.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTPro-UltLtCn.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTPro-UltLtCn.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTPro-UltLtCn.svg#HelveticaNeueLTPro-UltLtCn') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('../../fonts/HelveticaNeueLTPro-ThCn.eot');
    src: url('../../fonts/HelveticaNeueLTPro-ThCn.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTPro-ThCn.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTPro-ThCn.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTPro-ThCn.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTPro-ThCn.svg#HelveticaNeueLTPro-ThCn') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: url('../../fonts/HelveticaNeueLTPro-UltLt.eot');
    src: url('../../fonts/HelveticaNeueLTPro-UltLt.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/HelveticaNeueLTPro-UltLt.woff2') format('woff2'),
        url('../../fonts/HelveticaNeueLTPro-UltLt.woff') format('woff'),
        url('../../fonts/HelveticaNeueLTPro-UltLt.ttf') format('truetype'),
        url('../../fonts/HelveticaNeueLTPro-UltLt.svg#HelveticaNeueLTPro-UltLt') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@font-face {
    font-family: 'Cairo';
    src: url('../../fonts/ar/Cairo-Bold.woff2') format('woff2'),
        url('../../fonts/ar/Cairo-Bold.woff') format('woff'),
        url('../../fonts/ar/Cairo-Bold.ttf') format('truetype'),
        url('../../fonts/ar/Cairo-Bold.svg#Cairo-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cairo';
    src: url('../../fonts/ar/Cairo-Black.woff2') format('woff2'),
        url('../../fonts/ar/Cairo-Black.woff') format('woff'),
        url('../../fonts/ar/Cairo-Black.ttf') format('truetype'),
        url('../../fonts/ar/Cairo-Black.svg#Cairo-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cairo';
    src: url('../../fonts/ar/Cairo-ExtraBold.woff2') format('woff2'),
        url('../../fonts/ar/Cairo-ExtraBold.woff') format('woff'),
        url('../../fonts/ar/Cairo-ExtraBold.ttf') format('truetype'),
        url('../../fonts/ar/Cairo-ExtraBold.svg#Cairo-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cairo';
    src: url('../../fonts/ar/Cairo-Medium.woff2') format('woff2'),
        url('../../fonts/ar/Cairo-Medium.woff') format('woff'),
        url('../../fonts/ar/Cairo-Medium.ttf') format('truetype'),
        url('../../fonts/ar/Cairo-Medium.svg#Cairo-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cairo';
    src: url('../../fonts/ar/Cairo-Light.woff2') format('woff2'),
        url('../../fonts/ar/Cairo-Light.woff') format('woff'),
        url('../../fonts/ar/Cairo-Light.ttf') format('truetype'),
        url('../../fonts/ar/Cairo-Light.svg#Cairo-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cairo';
    src: url('../../fonts/ar/Cairo-ExtraLight.woff2') format('woff2'),
        url('../../fonts/ar/Cairo-ExtraLight.woff') format('woff'),
        url('../../fonts/ar/Cairo-ExtraLight.ttf') format('truetype'),
        url('../../fonts/ar/Cairo-ExtraLight.svg#Cairo-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cairo';
    src: url('../../fonts/ar/Cairo-SemiBold.woff2') format('woff2'),
        url('../../fonts/ar/Cairo-SemiBold.woff') format('woff'),
        url('../../fonts/ar/Cairo-SemiBold.ttf') format('truetype'),
        url('../../fonts/ar/Cairo-SemiBold.svg#Cairo-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cairo';
    src: url('../../fonts/ar/Cairo-Regular.woff2') format('woff2'),
        url('../../fonts/ar/Cairo-Regular.woff') format('woff'),
        url('../../fonts/ar/Cairo-Regular.ttf') format('truetype'),
        url('../../fonts/ar/Cairo-Regular.svg#Cairo-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

////////////////////////////////////////////////////////////////////////
@font-face {
    font-family: 'DIN-NEXT';
    src: url('../../fonts/ar/DIN-NEXT-ARABIC-BOLD.otf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'DIN-NEXT';
    src: url('../../fonts/ar/DIN-NEXT-ARABIC-LIGHT.otf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'DIN-NEXT';
    src: url('../../fonts/ar/DIN-NEXT-ARABIC-MEDIUM.otf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'DIN-NEXT';
    src: url('../../fonts/ar/DIN-NEXT-ARABIC-REGULAR.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@font-face {
    font-family: 'D-DIN';
    src: url('../../fonts/D-DIN-Italic.eot');
    src: url('../../fonts/D-DIN-Italic.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/D-DIN-Italic.woff2') format('woff2'),
        url('../../fonts/D-DIN-Italic.woff') format('woff'),
        url('../../fonts/D-DIN-Italic.ttf') format('truetype'),
        url('../../fonts/D-DIN-Italic.svg#D-DIN-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN Exp';
    src: url('../../fonts/D-DINExp.eot');
    src: url('../../fonts/D-DINExp.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/D-DINExp.woff2') format('woff2'),
        url('../../fonts/D-DINExp.woff') format('woff'),
        url('../../fonts/D-DINExp.ttf') format('truetype'),
        url('../../fonts/D-DINExp.svg#D-DINExp') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN';
    src: url('../../fonts/D-DIN.eot');
    src: url('../../fonts/D-DIN.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/D-DIN.woff2') format('woff2'),
        url('../../fonts/D-DIN.woff') format('woff'),
        url('../../fonts/D-DIN.ttf') format('truetype'),
        url('../../fonts/D-DIN.svg#D-DIN') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN';
    src: url('../../fonts/D-DIN_1.eot');
    src: url('../../fonts/D-DIN_1.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/D-DIN_1.woff2') format('woff2'),
        url('../../fonts/D-DIN_1.woff') format('woff'),
        url('../../fonts/D-DIN_1.ttf') format('truetype'),
        url('../../fonts/D-DIN_1.svg#D-DIN') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN Condensed';
    src: url('../../fonts/D-DINCondensed-Bold.eot');
    src: url('../../fonts/D-DINCondensed-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/D-DINCondensed-Bold.woff2') format('woff2'),
        url('../../fonts/D-DINCondensed-Bold.woff') format('woff'),
        url('../../fonts/D-DINCondensed-Bold.ttf') format('truetype'),
        url('../../fonts/D-DINCondensed-Bold.svg#D-DINCondensed-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN Exp';
    src: url('../../fonts/D-DINExp-Bold.eot');
    src: url('../../fonts/D-DINExp-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/D-DINExp-Bold.woff2') format('woff2'),
        url('../../fonts/D-DINExp-Bold.woff') format('woff'),
        url('../../fonts/D-DINExp-Bold.ttf') format('truetype'),
        url('../../fonts/D-DINExp-Bold.svg#D-DINExp-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN Condensed';
    src: url('../../fonts/D-DINCondensed-Bold_1.eot');
    src: url('../../fonts/D-DINCondensed-Bold_1.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/D-DINCondensed-Bold_1.woff2') format('woff2'),
        url('../../fonts/D-DINCondensed-Bold_1.woff') format('woff'),
        url('../../fonts/D-DINCondensed-Bold_1.ttf') format('truetype'),
        url('../../fonts/D-DINCondensed-Bold_1.svg#D-DINCondensed-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN';
    src: url('../../fonts/D-DIN-Bold.eot');
    src: url('../../fonts/D-DIN-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/D-DIN-Bold.woff2') format('woff2'),
        url('../../fonts/D-DIN-Bold.woff') format('woff'),
        url('../../fonts/D-DIN-Bold.ttf') format('truetype'),
        url('../../fonts/D-DIN-Bold.svg#D-DIN-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN Condensed';
    src: url('../../fonts/D-DINCondensed.eot');
    src: url('../../fonts/D-DINCondensed.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/D-DINCondensed.woff2') format('woff2'),
        url('../../fonts/D-DINCondensed.woff') format('woff'),
        url('../../fonts/D-DINCondensed.ttf') format('truetype'),
        url('../../fonts/D-DINCondensed.svg#D-DINCondensed') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN Exp';
    src: url('../../fonts/D-DINExp_1.eot');
    src: url('../../fonts/D-DINExp_1.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/D-DINExp_1.woff2') format('woff2'),
        url('../../fonts/D-DINExp_1.woff') format('woff'),
        url('../../fonts/D-DINExp_1.ttf') format('truetype'),
        url('../../fonts/D-DINExp_1.svg#D-DINExp') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN Condensed';
    src: url('../../fonts/D-DINCondensed_1.eot');
    src: url('../../fonts/D-DINCondensed_1.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/D-DINCondensed_1.woff2') format('woff2'),
        url('../../fonts/D-DINCondensed_1.woff') format('woff'),
        url('../../fonts/D-DINCondensed_1.ttf') format('truetype'),
        url('../../fonts/D-DINCondensed_1.svg#D-DINCondensed') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN';
    src: url('../../fonts/D-DIN-Bold_1.eot');
    src: url('../../fonts/D-DIN-Bold_1.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/D-DIN-Bold_1.woff2') format('woff2'),
        url('../../fonts/D-DIN-Bold_1.woff') format('woff'),
        url('../../fonts/D-DIN-Bold_1.ttf') format('truetype'),
        url('../../fonts/D-DIN-Bold_1.svg#D-DIN-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN';
    src: url('../../fonts/D-DIN-Italic_1.eot');
    src: url('../../fonts/D-DIN-Italic_1.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/D-DIN-Italic_1.woff2') format('woff2'),
        url('../../fonts/D-DIN-Italic_1.woff') format('woff'),
        url('../../fonts/D-DIN-Italic_1.ttf') format('truetype'),
        url('../../fonts/D-DIN-Italic_1.svg#D-DIN-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN Condensed';
    src: url('../../fonts/D-DINCondensed_2.eot');
    src: url('../../fonts/D-DINCondensed_2.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/D-DINCondensed_2.woff2') format('woff2'),
        url('../../fonts/D-DINCondensed_2.woff') format('woff'),
        url('../../fonts/D-DINCondensed_2.ttf') format('truetype'),
        url('../../fonts/D-DINCondensed_2.svg#D-DINCondensed') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN Exp';
    src: url('../../fonts/D-DINExp-Bold_1.eot');
    src: url('../../fonts/D-DINExp-Bold_1.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/D-DINExp-Bold_1.woff2') format('woff2'),
        url('../../fonts/D-DINExp-Bold_1.woff') format('woff'),
        url('../../fonts/D-DINExp-Bold_1.ttf') format('truetype'),
        url('../../fonts/D-DINExp-Bold_1.svg#D-DINExp-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN Condensed';
    src: url('../../fonts/D-DINCondensed_3.eot');
    src: url('../../fonts/D-DINCondensed_3.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/D-DINCondensed_3.woff2') format('woff2'),
        url('../../fonts/D-DINCondensed_3.woff') format('woff'),
        url('../../fonts/D-DINCondensed_3.ttf') format('truetype'),
        url('../../fonts/D-DINCondensed_3.svg#D-DINCondensed') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN';
    src: url('../../fonts/D-DIN_2.eot');
    src: url('../../fonts/D-DIN_2.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/D-DIN_2.woff2') format('woff2'),
        url('../../fonts/D-DIN_2.woff') format('woff'),
        url('../../fonts/D-DIN_2.ttf') format('truetype'),
        url('../../fonts/D-DIN_2.svg#D-DIN') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN Condensed';
    src: url('../../fonts/D-DINCondensed-Bold_2.eot');
    src: url('../../fonts/D-DINCondensed-Bold_2.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/D-DINCondensed-Bold_2.woff2') format('woff2'),
        url('../../fonts/D-DINCondensed-Bold_2.woff') format('woff'),
        url('../../fonts/D-DINCondensed-Bold_2.ttf') format('truetype'),
        url('../../fonts/D-DINCondensed-Bold_2.svg#D-DINCondensed-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN Exp';
    src: url('../../fonts/D-DINExp_2.eot');
    src: url('../../fonts/D-DINExp_2.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/D-DINExp_2.woff2') format('woff2'),
        url('../../fonts/D-DINExp_2.woff') format('woff'),
        url('../../fonts/D-DINExp_2.ttf') format('truetype'),
        url('../../fonts/D-DINExp_2.svg#D-DINExp') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN Exp';
    src: url('../../fonts/D-DINExp_3.eot');
    src: url('../../fonts/D-DINExp_3.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/D-DINExp_3.woff2') format('woff2'),
        url('../../fonts/D-DINExp_3.woff') format('woff'),
        url('../../fonts/D-DINExp_3.ttf') format('truetype'),
        url('../../fonts/D-DINExp_3.svg#D-DINExp') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN Exp';
    src: url('../../fonts/D-DINExp-Italic.eot');
    src: url('../../fonts/D-DINExp-Italic.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/D-DINExp-Italic.woff2') format('woff2'),
        url('../../fonts/D-DINExp-Italic.woff') format('woff'),
        url('../../fonts/D-DINExp-Italic.ttf') format('truetype'),
        url('../../fonts/D-DINExp-Italic.svg#D-DINExp-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN Exp';
    src: url('../../fonts/D-DINExp-Bold_2.eot');
    src: url('../../fonts/D-DINExp-Bold_2.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/D-DINExp-Bold_2.woff2') format('woff2'),
        url('../../fonts/D-DINExp-Bold_2.woff') format('woff'),
        url('../../fonts/D-DINExp-Bold_2.ttf') format('truetype'),
        url('../../fonts/D-DINExp-Bold_2.svg#D-DINExp-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN Condensed';
    src: url('../../fonts/D-DINCondensed-Bold_3.eot');
    src: url('../../fonts/D-DINCondensed-Bold_3.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/D-DINCondensed-Bold_3.woff2') format('woff2'),
        url('../../fonts/D-DINCondensed-Bold_3.woff') format('woff'),
        url('../../fonts/D-DINCondensed-Bold_3.ttf') format('truetype'),
        url('../../fonts/D-DINCondensed-Bold_3.svg#D-DINCondensed-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN';
    src: url('../../fonts/D-DIN-Italic_2.eot');
    src: url('../../fonts/D-DIN-Italic_2.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/D-DIN-Italic_2.woff2') format('woff2'),
        url('../../fonts/D-DIN-Italic_2.woff') format('woff'),
        url('../../fonts/D-DIN-Italic_2.ttf') format('truetype'),
        url('../../fonts/D-DIN-Italic_2.svg#D-DIN-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN';
    src: url('../../fonts/D-DIN-Italic_3.eot');
    src: url('../../fonts/D-DIN-Italic_3.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/D-DIN-Italic_3.woff2') format('woff2'),
        url('../../fonts/D-DIN-Italic_3.woff') format('woff'),
        url('../../fonts/D-DIN-Italic_3.ttf') format('truetype'),
        url('../../fonts/D-DIN-Italic_3.svg#D-DIN-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN';
    src: url('../../fonts/D-DIN_3.eot');
    src: url('../../fonts/D-DIN_3.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/D-DIN_3.woff2') format('woff2'),
        url('../../fonts/D-DIN_3.woff') format('woff'),
        url('../../fonts/D-DIN_3.ttf') format('truetype'),
        url('../../fonts/D-DIN_3.svg#D-DIN') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN Exp';
    src: url('../../fonts/D-DINExp-Italic_1.eot');
    src: url('../../fonts/D-DINExp-Italic_1.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/D-DINExp-Italic_1.woff2') format('woff2'),
        url('../../fonts/D-DINExp-Italic_1.woff') format('woff'),
        url('../../fonts/D-DINExp-Italic_1.ttf') format('truetype'),
        url('../../fonts/D-DINExp-Italic_1.svg#D-DINExp-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN Exp';
    src: url('../../fonts/D-DINExp-Italic_2.eot');
    src: url('../../fonts/D-DINExp-Italic_2.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/D-DINExp-Italic_2.woff2') format('woff2'),
        url('../../fonts/D-DINExp-Italic_2.woff') format('woff'),
        url('../../fonts/D-DINExp-Italic_2.ttf') format('truetype'),
        url('../../fonts/D-DINExp-Italic_2.svg#D-DINExp-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN Exp';
    src: url('../../fonts/D-DINExp-Bold_3.eot');
    src: url('../../fonts/D-DINExp-Bold_3.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/D-DINExp-Bold_3.woff2') format('woff2'),
        url('../../fonts/D-DINExp-Bold_3.woff') format('woff'),
        url('../../fonts/D-DINExp-Bold_3.ttf') format('truetype'),
        url('../../fonts/D-DINExp-Bold_3.svg#D-DINExp-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN Exp';
    src: url('../../fonts/D-DINExp-Italic_3.eot');
    src: url('../../fonts/D-DINExp-Italic_3.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/D-DINExp-Italic_3.woff2') format('woff2'),
        url('../../fonts/D-DINExp-Italic_3.woff') format('woff'),
        url('../../fonts/D-DINExp-Italic_3.ttf') format('truetype'),
        url('../../fonts/D-DINExp-Italic_3.svg#D-DINExp-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}



/* ====================================== _____________ Fonts Styles _____________ ====================================== */
