/* ====================================== _____________ Classes Styles _____________ ====================================== */
@use './variables' as vars;
@use './mixins' as mix;

// == Container //
.left-container {
   margin: 0 auto;
   padding: 0 calc(1.5rem * 0.5);
   width: 100%;

   // overflow: hidden;
   @include mix.respond(small) {
      width: 100%;
   }

   @include mix.respond(landscape) {
      width: 540px;
   }

   @include mix.respond(medium) {
      width: calc(720px + (((100vw - 720px) / 2)));
      padding-right: 0;
      margin-right: 0;
   }

   @include mix.respond(large) {
      max-width: calc(960px + (((100vw - 960px) / 2)));
      padding-right: 0;
      margin-right: 0;
   }

   @include mix.respond(xLarge) {
      width: calc(1140px + (((100vw - 1140px) / 2)));
      padding-right: 0;
      margin-right: 0;
   }

   @include mix.respond(xxLarge) {
      width: calc(1320px + ((100vw - 1320px) / 2));
      padding-right: 0;
      margin-right: 0;
   }
}

.right-container {
   margin: 0 auto;
   padding: 0 calc(1.5rem * 0.5);
   width: 100%;

   // overflow: hidden;
   @include mix.respond(small) {
      width: 100%;
   }

   @include mix.respond(landscape) {
      width: 540px;
   }

   @include mix.respond(medium) {
      width: calc(720px + (((100vw - 720px) / 2)));
      padding-left: 0;
      margin-left: 0;
   }

   @include mix.respond(large) {
      max-width: calc(960px + (((100vw - 960px) / 2)));
      padding-left: 0;
      margin-left: 0;
   }

   @include mix.respond(xLarge) {
      width: calc(1140px + (((100vw - 1140px) / 2)));
      padding-left: 0;
      margin-left: 0;
   }

   @include mix.respond(xxLarge) {
      width: calc(1320px + ((100vw - 1320px) / 2));
      padding-left: 0;
      margin-left: 0;
   }
}

// == Color //
.mainColor {
   color: vars.$mainColor !important;

   h2 {
      color: vars.$mainColor !important;
   }
}

.secColor {
   color: vars.$secColor !important;
}

.thirdColor {
   color: vars.$thirdColor !important;
}

.white {
   color: vars.$white !important;
}

.black {
   color: vars.$black !important;
}

.textColor {
   color: vars.$textColor !important;
}

// == Color //

// == Bg Color //
.mainBgColor {
   background-color: vars.$mainBgColor !important;
}

.secBgColor {
   background-color: vars.$secColor !important;
}

.whtBg {
   background-color: vars.$white !important;
}

.blkBg {
   background-color: vars.$black !important;
}

.offBg {
   background-color: rgba(187, 187, 187, 0.1) !important;
}

.pendingBg {
   background-color: #d58a12 !important;
}

.errorBg {
   background-color: #cf1313 !important;
}

.successBg {
   background-color: #5cab1b !important;
}

// == Bg Color //

// == Fonts //
.font_light {
   font-weight: 300;
}

.font_regular {
   font-weight: 400;
}

.font_medium {
   font-weight: 600;
}

.font_bold {
   font-weight: 800;
}

.font_bolder {
   font-weight: 900;
}

.font-eng {
   font-family: vars.$englishFont !important;
}

// == Fonts //

// == Title //
.title {
   font-size: vars.$font-2300;
   font-weight: bold;
   color: vars.$thirdColor;
   text-align: center;
   margin-bottom: 1.5rem;
}

// == Texts //
p,
.p {
   font-size: vars.$font-1100;
   font-weight: 400;
   color: vars.$textColor;
   margin-bottom: 1rem;

   &.big {
      font-size: vars.$font-1200;
   }
}

.text {
   font-size: vars.$font-1200;
   font-weight: 400;
   color: vars.$textColor;
   text-align: center;
   margin-bottom: 1.5rem;
}

// == Texts //


.zoomIn {
   overflow: hidden;
   margin: 0;
   width: 100%;
   height: 100%;

   img {
      transition: vars.$transition;
      width: 100%;
      height: 100%;
      object-fit: cover;
   }

   &:hover {
      img {
         transform: scale(1.04);
      }
   }
}

.zoomOut {
   overflow: hidden;
   margin: 0;

   img {
      transition: vars.$sl-transition;
   }

   &:hover {
      img {
         transform: scale(0.925);
      }
   }
}



// == Img //


// == Box Shadow //
.boxShadow {
   box-shadow: 0 3px 6px rgba($color: vars.$black, $alpha: 0.16) !important;
}

// == Box Shadow //

// == Dir //
.dir-ltr {
   direction: ltr;
}

.dir-rtl {
   direction: rtl;
}

// == Dir //

// == Width //
.w-50I {
   width: 60% !important;
}
.w-50 {
   @include mix.respond(large2) {
      width: 75% !important;
   }

   @include mix.respond(medium2) {
      width: 100% !important;
   }
}

.w-90 {
   width: 90%;
   margin: auto;

   @include mix.respond(medium2) {
      width: 100% !important;
   }
}

.w-1 {
   width: 0.25rem;
}

.w-2 {
   width: 0.5rem;
}

.w-3 {
   width: 0.75rem;
}

.w-4 {
   width: 1rem;
}

.w-5 {
   width: 1.25rem;
}

.w-6 {
   width: 1.5rem;
}

.w-7 {
   width: 1.75rem;
}

.w-8 {
   width: 2rem;
}

// == Width //

// == invalid //
.invalid {
   color: red;
   font-size: vars.$font-1000;
   text-transform: none;
}

.valid {
   color: green;
   font-size: vars.$font-1000;
   text-transform: none;
   font-weight: bold;
}

// == invalid //

.activeLink {
   color: vars.$mainColor !important;
   font-weight: bold !important;
   font-family: 'HelveticaLT';

   &:before {
      content: "";
      width: 100%;
      height: 3px;
      background-color: vars.$mainColor;
      position: absolute;
      left: 0;

      bottom: -32px;

      @media (max-width: 1200px) {
         bottom: -33px;
      }

      @include mix.respond(medium2) {
         bottom: -5px;
         height: 2px;
      }
   }
}

// scroll navbar
.scrolled {
   position: fixed !important;
   width: max-content !important;
   display: flex !important;
   background-color: rgba($color: #fff, $alpha: 0.95) !important;
   padding: 32px 50px !important;

   .remove,
   .header_switchLg,
   .removeSm {
      display: none !important;
   }

   @include mix.respond(large) {
      height: 88px;
   }

   @include mix.respond(medium2) {
      width: 80% !important;
      padding: 8px 20px !important;

      // .remove {
      //    display: inline-block !important;
      // }
      .removeSm {
         display: inline-block !important;
      }
   }
}

.md-h-100 {
   @media (min-width: 768px) {
      height: 100%;
   }
}

.scrolledSidebar {
   @media (max-width: 1200px) {
      height: calc(100vh - 99px);
      top: 99px;
   }
}

// == evenOddSection //
.evenOddSection,
.evenOddSection3,
.evenOddSection4 {
   overflow: hidden;
   // border-radius: 5px;

   &:nth-child(odd) {
      .col1 {
         order: 1 !important;
      }

      .col2 {
         order: 2 !important;
      }

      @include mix.respond(medium2) {
         .col1 {
            order: 2 !important;
         }

         .col2 {
            order: 1 !important;
         }
      }
   }

   &:nth-child(even) {
      .col1 {
         order: 2 !important;
      }

      .col2 {
         order: 1 !important;
      }

      @include mix.respond(medium2) {
         .col1 {
            order: 2 !important;
         }

         .col2 {
            order: 1 !important;
         }
      }
   }

   &:first-child {
      .removeInText {
         display: none !important;
      }

      .addInText {
         display: block !important;
      }

   }
}

.evenOddSection2 {
   margin-bottom: 1.5rem;

   &:last-child {
      margin-bottom: 0;
   }

   &:nth-child(odd) {
      img {
         padding-inline-end: 4rem;

         @include mix.respond(medium2) {
            padding-inline-end: 0;
         }
      }

      .col1 {
         order: 2 !important;
      }

      .col2 {
         order: 1 !important;
      }

      @include mix.respond(medium2) {
         .col1 {
            order: 2 !important;
         }

         .col2 {
            order: 1 !important;
         }
      }
   }

   &:nth-child(even) {
      background-color: vars.$mainBgColor;

      img {
         padding-inline-start: 4rem;

         @include mix.respond(medium2) {
            padding-inline-start: 0;
         }
      }

      .col1 {
         order: 1 !important;
      }

      .col2 {
         order: 2 !important;
      }

      @include mix.respond(medium2) {
         .col1 {
            order: 2 !important;
         }

         .col2 {
            order: 1 !important;
         }
      }
   }

}

.evenOddSection2 {
   margin-bottom: 1.5rem;

   &:last-child {
      margin-bottom: 0;
   }

   &:nth-child(odd) {
      img {
         padding-inline-end: 4rem;

         @include mix.respond(medium2) {
            padding-inline-end: 0;
         }
      }

      .col1 {
         order: 2 !important;
      }

      .col2 {
         order: 1 !important;
      }

      @include mix.respond(medium2) {
         .col1 {
            order: 2 !important;
         }

         .col2 {
            order: 1 !important;
         }
      }
   }

   &:nth-child(even) {
      background-color: vars.$mainBgColor;

      img {
         padding-inline-start: 4rem;

         @include mix.respond(medium2) {
            padding-inline-start: 0;
         }
      }

      .col1 {
         order: 1 !important;
      }

      .col2 {
         order: 2 !important;
      }

      @include mix.respond(medium2) {
         .col1 {
            order: 2 !important;
         }

         .col2 {
            order: 1 !important;
         }
      }
   }

}

.evenOddSection3,
.evenOddSection {
   &:nth-child(odd) {
      .psOdd {
         @media (min-width: 991px) {
            padding-inline-start: 15px;
         }
      }
   }

   &:nth-child(even) {
      background-color: vars.$mainBgColor;

   }

}

.evenOddSection4 {
   &:nth-child(odd) {
  
      .col1 {
         order: 2 !important;
        
      }

      .col2 {
         order: 1 !important;
      }
      .psOdd {
         @media (min-width: 991px) {
            padding-inline-start: 4.5rem;
         }
      }

   }

   &:nth-child(even) {
      background-color: vars.$mainBgColor;
      .psOdd {
         @media (min-width: 991px) {
            padding-inline-start: 15px;
         }
      }
      .col1 {
         order: 1 !important;
      }

      .col2 {
         order: 2 !important;
      }

      @include mix.respond(medium2) {
         .col1 {
            order: 2 !important;
         }

         .col2 {
            order: 1 !important;
         }
      }
   }
}


// == gridContainer //
.grid-container {
   display: grid;
   grid-template-columns: repeat(3, 1fr); // 3 equal columns
   // grid-template-rows: repeat(2, auto);  // 2 equal rows
   grid-gap: 20px;
 
   @include mix.respond(medium2) {
     grid-template-columns: repeat(2, 1fr); // Switch to 2 columns for smaller screens
     grid-template-rows: auto; // Adjust rows for fewer columns
   }
 
   @media (max-width: 568px) {
     grid-gap: 10px;
     grid-template-columns: 1fr; // Single column for mobile
   }
 
   .box1 {
     grid-column: 1;
     grid-row: 1; // First column, first row
   }
 
   .box2 {
     grid-column: 1;
     grid-row: 2; // First column, second row
   }
 
   .box3 {
     grid-column: 2;
     grid-row: 1; // Second column, first row
   }
 
   .box4 {
     grid-column: 2;
     grid-row: 2; // Second column, second row
   }
 
   .box5 {
     grid-column: 3;
     grid-row: 1; // Third column, first row
   }
 
   .box6 {
     grid-column: 3;
     grid-row: 2; // Third column, second row
   }
 }
 

.timelineAr {
   .timelineArDir {
      right: 85px !important;
      left: auto !important;

      @include mix.respond(medium2) {
         right: 55px !important;
         left: auto !important;
      }

      .timelineArDirList {
         .timelineArDirListItem {
            &::before {
               right: -24px !important;
               left: auto !important;
            }
         }
      }
   }
}

.timeline .swiper-button-disabled {
   display: none;
}

// dateActive 
.dateActive {
   font-weight: bold;
   color: white !important;
   background-color: vars.$mainColor;
   font-size: vars.$font-900;

   &::before {
      left: -26.5px !important;
      width: 18px !important;
      height: 18px !important;
      background-color: vars.$mainColor !important;
   }
}

.empty {
   color: red;
   margin-bottom: 0;
   font-size: vars.$font-2000;
   text-align: center;
}

.secondTitle {
   color: black;
   font-size: vars.$font-1600;
   margin-bottom: 1.25rem;
}

.thirdTitle {
   color: black;
   font-size: vars.$font-1800;
   margin-bottom: 1.35rem;
}

.logoBox {
   border: 1px solid rgba(40, 59, 138, 0.1);
   border-radius: 20px;
   text-align: center;
   height: 100%;
   padding: 10px;

   img {
      width: 100%;

      &.icon {
         width: 75px;
      }
   }

   h5 {
      color: #343D48;
      font-size: vars.$font-1000;
      margin-bottom: 1rem;
      font-weight: 700;
   }
}

.w-90 {
   @media (min-width: 991px) {
      width: 95%;
      margin: auto;
   }
}

.defaultUl {
   li {
      font-size: vars.$font-1000;
      font-weight: 400;
      color: vars.$textColor;
   }

   &.bold {
      li {
         font-size: vars.$font-1200;
         font-weight: 400;
         color: black;
         margin-bottom: 0.5rem;
      }
   }
}

.bgTransparent {
   background-color: transparent !important;
}

small,
.small {
   font-size: 0.65em;
}

.smallWidth {
   width: 30%;

   @media (max-width: 568px) {
      width: 40%;
   }
}

.Toastify__toast-container {
   z-index: 999999999999999;
}

.newsTitle {
   color: #494949;
   font-family: 'BMW Helvetica';
   font-size: 2.5rem;
   text-align: center;

   margin-bottom: 4rem;
   padding-top: 8rem;
   font-weight: bold;
}

.newsDetails {
   padding-top: 9rem;

   &_content {
      &_date {
         color: #878787;
         font-size: 0.8rem;
         margin-bottom: 0.5rem;
         display: block;
      }

      &_title {
         color: black;
         font-size: vars.$font-1400;
         margin-bottom: 1rem;
      }

      &_desc {
         color: vars.$textColor;
   
      }

      &_img {
         width: 100%;
         object-fit: cover;
         height: 584px;
         padding: 1.5rem 0;
         object-fit: cover;

         @media (max-width: 768px) {
            height: auto;
         }
      }
   }

   &_contentBox {
      &_title {
         color: black;
         font-size: 1.2rem;
         margin-bottom: 1rem;
      }
   }
}

.searchNews {
   display: flex;
   width: 100%;
   justify-content: center;
   overflow: hidden;
   &_parent {
      width: 100%;
      border: 2px solid #BCBCBC;
      border-radius: 10px;
      display: flex;
      overflow: hidden;

   }

   &_input {
      color: #494949;
      font-size: 0.9rem;
      border: none;
      padding: 13px 20px;
      width: 97%;
      flex: 0 0 97%;

      &::placeholder {
         color: #C4C4C4;
         font-size: 0.9rem;
      }

      @media (max-width: 1200px) {
         width: 95%;
         flex: 0 0 95%;
      }

      @media (max-width: 991px) {
         width: 94%;
         flex: 0 0 94%;
      }

      @media (max-width: 768px) {
         width: 93%;
         flex: 0 0 93%;
      }

      @media (max-width: 568px) {
         width: 90%;
         flex: 0 0 90%;
      }

   }

   &_button {
      img {
         width: 18px;
         margin-bottom: 3px;
      }
   }
}

.servicesSlider {
   .swiper-button-disabled {
      display: none;
   }
}

.ProductSection_content_title {
   font-size: vars.$font-2200;
   color: vars.$mainColor;
   margin-bottom: 1.4rem;

   @media (max-width: 1200px) {
      margin-bottom: 1.5rem;
   }

   @include mix.respond(landscape2) {
      margin-bottom: 1.4rem;
   }
}

.smallTxt {
   font-size: 0.85rem;
   line-height: 1.8;
}

.SmallImg {
   width: 50px;
   object-fit: contain;
   padding-inline-end: 10px;
}

.smallTitle {
   color:
      #384142;
   font-weight: bold;
   font-size: 1.2rem;
}

.psCust {
   padding-inline-start: 15px;
}

.search {
   padding: 10rem 0;
}

/* ===  =================================== _____________ Classes Styles _____________ ====================================== */