/* ===  =================================== _____________ contactButton Styles _____________ ====================================== */
@use '../../../styles/sass/utilities/variables'as vars;
@use '../../../styles/sass/utilities/mixins'as mix;

// header link
.contactButton {
   background-color:  white;
   color: vars.$mainColor;
   border-radius: 5px;
   padding: 10px 23px;
   font-size: vars.$font-900;
   display: inline-block;
   font-family: 'BMW Helvetica';
   font-weight: bold;
   &:hover {
      background-color:  white;
      color: vars.$mainColor;
  }
}
/* ===  =================================== _____________ contactButton Styles _____________ ====================================== */
