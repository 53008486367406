/* ===  =================================== _____________ halfSection Styles _____________ ====================================== */
@use '../../styles/sass/utilities/variables' as vars;
@use '../../styles/sass/utilities/mixins' as mix;

.halfSection {
   background-color: vars.$mainBgColor;
   &_content {
      padding: 2.75rem 4.5rem;
      
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include mix.respond(medium2) {
         padding: 2rem 3rem;
      }

      @include mix.respond(landscape2) {
         padding: 2rem 2rem;
      }

      &_title {
         font-size: vars.$font-2000;
         color: vars.$mainColor;
         margin-bottom: 1.4rem;

         @media (max-width: 1200px) {
            margin-bottom: 1.5rem;
         }

         @include mix.respond(landscape2) {
            margin-bottom: 1.4rem;
         }
      }

      &_desc {
         color: 
         #343D48;
         font-size: vars.$font-950;
         margin-bottom: 0;
         padding-inline-end: 2rem;
         @media (max-width: 1200px) {
            padding-inline-end: 0;
         }
      }

      &_link {
         margin-top: 1rem;
         text-decoration: underline;
         color: vars.$mainColor;

         font-weight: 700;
         &:hover {
            text-decoration: underline;
            color: vars.$mainColor;
         }
      }
   }
}



/* ===  =================================== _____________ halfSection Styles _____________ ====================================== */