/* ===  =================================== _____________ LineBox Styles _____________ ====================================== */
@use '../../../styles/sass/utilities/variables' as vars;
@use '../../../styles/sass/utilities/mixins' as mix;

.lineBox {
   position: relative;
   overflow: hidden;
   background-color: white;
   padding: 4rem 3rem;
   height: 100%;
   display: flex;
   flex-direction: column-reverse;
   align-items: center;
   justify-content: center;
   &_img {
      width: 85px;
      margin-bottom: 1rem
   }
   &_content {
      text-align: center;

      &_title {
         color: vars.$mainColor;
         font-size: vars.$font-1800;
         margin-bottom: 1.5rem;
      }

      &_title2 {
         color: black !important;
         font-size: vars.$font-1500;

      }

      &_desc {
         font-size: vars.$font-1100;
         // margin-bottom: 0;
         color: #343D48;
         p {
            &:last-child {
               margin-bottom: 0;
            }
         }
         ul {
            list-style-position: inside;
            li {
               &::marker {
                  color: #343D48;
                  margin-top: -3px;
                  font-size: 0.75rem;
               }
            }
         }
      }

      // &_desc2 {
      //    p {
      //       padding-inline-start: 1rem;
      //       position: relative;
      //       display: inline-block;
      //       &::before {
      //          content: "";
      //          position: absolute;
      //          left: 0;
      //          width: 5px;
      //          height: 5px;
      //          border-radius: 50%;
      //          color: #343D48;
      //          background: #343D48;
      //          top: 50%;
      //          transform: translateY(-50%);

      //       }
      //    }
      // }
   }

   &_pattern {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 15px !important;
   }
}

/* ===  =================================== _____________ LineBox Styles _____________ ====================================== */