/* ====================================== _____________ halfSection Styles _____________ ====================================== */
@use '../../styles/sass/utilities/variables' as vars;
@use '../../styles/sass/utilities/mixins' as mix;

.halfSection {
   background-color: vars.$mainBgColor;
   overflow: hidden;
   &_content {
      padding: 2.75rem 3.5rem 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include mix.respond(medium2) {
         padding: 2rem 3rem;
      }

      @include mix.respond(landscape2) {
         padding: 2rem 2rem;
      }

      

      &_desc {
         color: vars.$textColor;
         font-size: vars.$font-1000;
         margin-bottom: 0;
      }

      &_link {
         margin-top: 1rem;
         text-decoration: underline;
         color: vars.$mainColor;

         font-weight: 700;
         &:hover {
            text-decoration: underline;
            color: vars.$mainColor;
         }
      }
   }
   &_imgContainer {
      height: 100%;
      &_logos {
         text-align: center;
         margin-bottom: 1rem;
         display: flex;
         align-items: center;
         justify-content: center;
         gap: 25px;
         flex-wrap: wrap-reverse;
         // img {
         //    width: auto;
         //    height: 164px;

         // }
      }
      &_img {
         // min-height: 100%;
         height: 500px;
         width: 100%;
         object-fit: cover;
         @media (max-width: 991px) {
            height: auto;
         min-height: auto;

         }
      }
   }
}



/* ====================================== _____________ halfSection Styles _____________ ====================================== */