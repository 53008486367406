/* ===  =================================== _____________ addresses Styles _____________ ====================================== */
@use '../../styles/sass/utilities/variables' as vars;
@use '../../styles/sass/utilities/mixins' as mix;

.contactUs {
   background-color: #ECF3FF;
   padding: 13rem 0 10rem;
   @media(max-width: 768px) {
   padding: 10rem 0;
   }
   @media(max-width: 568px) {
      padding: 10rem 0 7rem;
      }
   &_title {
      h2 {
         font-family: "BMW Helvetica";
         color: vars.$textColor;
         font-weight: bold;
         margin-bottom: 2rem;
         font-size: 3rem;
      }
   }
}



/* ===  =================================== _____________ addresses Styles _____________ ====================================== */