/* ===  =================================== _____________ title Styles _____________ ====================================== */
@use '../../styles/sass/utilities/variables' as vars;
@use '../../styles/sass/utilities/mixins' as mix;


.mainTitles {
   margin-bottom: 2.25rem;
   &_smallTitle {
      color: vars.$mainColor;
      font-size: vars.$font-800;
      font-weight: bold;
      margin-bottom: 1.1rem;
      text-transform: uppercase;
      letter-spacing: 1px;
   }
   &_title {
      // color: vars.$thirdColor;
      color: black;
      font-size: vars.$font-2200;
      
   }
}


/* ===  =================================== _____________ title Styles _____________ ====================================== */