/* ===  =================================== _____________ up Styles _____________ ====================================== */
@use '../../../styles/sass/utilities/variables' as vars;
@use '../../../styles/sass/utilities/mixins' as mix;

.up {
   background-color: white;
   box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);
   position: fixed;
   cursor: pointer;
   z-index: 99;
   display: flex;
   align-items: center;
   justify-content: center;
   width: 50px;
   height: 50px;
   border-radius: 5px;
   bottom: 20px;
   right: 20px;
   color: vars.$mainColor;
   font-size: 1.2rem;
}

.upAr {
   right: auto;
   left: 0;
} 


/* ===  =================================== _____________ up Styles _____________ ====================================== */