/* ====================================== _____________ Variables Styles _____________ ====================================== */
// start color//
$mainColor: #283B8A;
$secColor: #232323;
$thirdColor: #0F2137;
$lightBlueColor: #5AB9EA;
$white: #fff;
$black: #000;
$textColor: #494949;
$mainBgColor: rgba(217,217,217, 30%);

// end color//

// Start Bg Color//
$overlayBg: rgba(
  $color: $black,
  $alpha: 0.45,
);
// end Bg Color//

// Font Sizes //
$font-800: 0.8rem;
$font-850: 0.85rem;
$font-900: 0.9rem;
$font-950: 0.95rem;
$font-1000: 1rem;
$font-1100: 1.1rem;
$font-1200: 1.2rem;
$font-1300: 1.3rem;
$font-1400: 1.4rem;
$font-1500: 1.5rem;
$font-1600: 1.6rem;
$font-1700: 1.7rem;
$font-1800: 1.8rem;
$font-1900: 1.9rem;
$font-2000: 2rem;
$font-2100: 2.1rem;
$font-2200: 2.2rem;
$font-2300: 2.3rem;
$font-2400: 2.4rem;
$font-2500: 2.5rem;
$font-2600: 2.6rem;
$font-2700: 2.7rem;
$font-2800: 2.8rem;
$font-2900: 2.9rem;
$font-3000: 3rem;
$font-3100: 3.1rem;
$font-3200: 3.2rem;
$font-3300: 3.3rem;
$font-3400: 3.4rem;
$font-3500: 3.5rem;
$font-3600: 3.6rem;
$font-3700: 3.7rem;
$font-3800: 3.8rem;
$font-3900: 3.9rem;
$font-4000: 4rem;
$font-4100: 4.1rem;
$font-4200: 4.2rem;
$font-4300: 4.3rem;
$font-4400: 4.4rem;
$font-4500: 4.5rem;
$font-4600: 4.6rem;
$font-4700: 4.7rem;
$font-4800: 4.8rem;
$font-4900: 4.9rem;
$font-5000: 5rem;
// Font Sizes //

// Transition //
$fs-transition: all 0.2s ease-in-out;
$transition: all 0.3s ease-in-out;
$sl-transition: all 0.5s ease-in-out;
// Transition //

// Transition //
$box-shadow: 0 3px 6px
  rgba(
    $color: $black,
    $alpha: 0.16,
  );
// Transition //

// Fonts Family //
$englishFont: 'Helvetica';
// $arabicFont: 'Cairo';
$arabicFont: 'DIN-NEXT' !important;
// Fonts Family //
/* ====================================== _____________ Variables Styles _____________ ====================================== */
