/* ===  =================================== _____________ detailsBox Styles _____________ ====================================== */
@use '../../../styles/sass/utilities/variables' as vars;
@use '../../../styles/sass/utilities/mixins' as mix;

.certificatesBox {
   // border-radius: 20px;
   margin-bottom: 3rem;
   padding: 3rem 1.5rem 0;
   text-align: center;
   height: 100%;
   box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
   @include mix.respond(landscape2) {
      margin-bottom: 2rem;
   }
   &_img {
      width: 80%;
   }
   &_title {
      color: vars.$textColor;
      font-size: 1.3rem;
      font-weight: bold;
      padding: 1rem 0;
      margin-bottom: 0;
   }
   &_desc {
      color: vars.$textColor;
      font-weight: 400;
      font-size: 0.9rem;
      line-height: 1.3;
      margin-bottom: 0;
   }

}



/* ===  =================================== _____________ detailsBox Styles _____________ ====================================== */