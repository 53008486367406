/* ===  =================================== _____________ internalSection Styles _____________ ====================================== */
@use '../../styles/sass/utilities/variables' as vars;
@use '../../styles/sass/utilities/mixins' as mix;

.internalSection {
   &_content {
      padding: 2rem 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include mix.respond(medium2) {
         padding: 2rem 3rem;
      }

      @include mix.respond(landscape2) {
         padding: 2rem;
      }

      &_title {
         font-size: vars.$font-2500;
         color: vars.$mainColor;
         margin-bottom: 1.4rem;

         @media (max-width: 1200px) {
            margin-bottom: 1.5rem;
         }

         @include mix.respond(landscape2) {
            margin-bottom: 1.4rem;
         }
      }

      &_img {
         // max-height: 380px;
         margin-bottom: 1.5rem !important;
         position: relative;

         &_details {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgb(0, 0, 0);
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.1) 100%);

            &_desc {
               position: absolute;
               bottom: 30px;
               width: 100%;
               justify-content: center;

               p {
                  color: white;
                  font-size: vars.$font-1300;
                  margin-bottom: 0;
               }
            }
         }

         img {
            width: 100%;
            @media (max-width: 768px) {
               height: 25vh;
               object-fit: cover;
            }
         }
      }

      &_desc,
      &_desc p {
         color: vars.$textColor;
         font-size: vars.$font-1100;

         h3 {
            font-size: vars.$font-1300;
            color: black;
            margin-bottom: 1rem;

         }

         &:last-child {
            margin-bottom: 0;
         }
      }
   }

   &_services {
      &_title {
         color: black;
         font-size: vars.$font-1600;
         margin-bottom: 1.25rem;
      }
   }
}

/* ===  =================================== _____________ internalSection Styles _____________ ====================================== */