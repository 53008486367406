/* ===  =================================== _____________ newsCard Styles _____________ ====================================== */
@use '../../../styles/sass/utilities/variables' as vars;
@use '../../../styles/sass/utilities/mixins' as mix;

.searchCard {
   box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.08);
   height: 100%;
   padding: 1.5rem;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   &_title {
      color: black;
      font-size: vars.$font-1400;
      margin-bottom: 1rem;
   }

   &_desc {
      color: vars.$textColor;
      font-size: vars.$font-1000;
   }

   &_link {
      text-decoration: underline;
      color: vars.$mainColor;
      font-weight: 700;
      font-size: 0.8rem;

      &:hover {
         text-decoration: underline;
         color: vars.$mainColor;
      }
   }

}



/* ===  =================================== _____________ newsCard Styles _____________ ====================================== */