/* ===  =================================== _____________ halfSection2 Styles _____________ ====================================== */
@use '../../styles/sass/utilities/variables' as vars;
@use '../../styles/sass/utilities/mixins' as mix;

.halfSection2 {
   &_content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &_desc {
         color: vars.$textColor;
         font-size: vars.$font-1200;
         line-height: 1.7rem;
         margin-bottom: 0;
      }
   }
}



/* ===  =================================== _____________ halfSection2 Styles _____________ ====================================== */