/* ====================================== _____________ Reset Styles _____________ ====================================== */

@use '../utilities/variables' as vars;
@use '../utilities/mixins' as mix;

* {
  outline: 0 !important;
  box-shadow: none;
}

a,
a:hover {
  text-decoration: none;
  color: inherit;
}

button {
  border: none;
  background-color: transparent;
}

html {
  font-size: 100%;

  @include mix.respond(small) {
    font-size: 82%;
  }

  @include mix.respond(landscape) {
    font-size: 85%;
  }

  @include mix.respond(medium) {
    font-size: 90%;
  }

  @include mix.respond(large) {
    font-size: 95%;
  }

  @include mix.respond(xLarge2) {
    font-size: 100%;
  }

  font-size: vars.$font-1000;
  font-weight: 400;
  scroll-behavior: smooth;
}

body {
  font-size: vars.$font-1000;
  font-weight: 400;
  font-family: vars.$englishFont;
  overflow-x: hidden;

  &.ar {
    font-family: vars.$arabicFont;
    direction: rtl;
    text-align: right;

    * {
      font-family: vars.$arabicFont;
    }

  }
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 1rem !important;
}

.swiper-button-prev,
.swiper-button-next {
  width: 50px !important;
  height: 50px !important;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  color: black !important;

  @include mix.respond(small) {
    width: 35px !important;
    height: 35px !important;
  }

}

.swiper-button-disabled {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.8) !important;
}

.dropdown-toggle {
  padding: 0;

  &::after {
    display: none;
  }
}

.nav-link {
  &:hover {
    color: white
  }
}

.dropdown-menu.show {
  display: contents;

  a {
    color: white;
    display: block;
    font-size: vars.$font-850;
    font-weight: 300;
    padding-bottom: 0.5rem;
    border-inline-start: 0.25px solid rgba(255, 255, 255, 0.2);
    padding-inline-start: 0.35rem;
    margin-inline-start: 1.5rem;
    text-transform: capitalize;
    width: max-content;

    &:first-child {
      margin-top: 1rem;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.servicesSlider {

  .swiper-button-prev,
  .swiper-button-next {
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 10px 5px rgb(0 0 0 / 8%);
    color: rgba(255, 255, 255, 0.7) !important;
  }
}

.timelineHistory {

  .swiper-button-prev,
  .swiper-button-next {
    top: 50%;
    transform: translateY(-50%);
    width: 35px !important;
    height: 35px !important;
    border-radius: 0;
    width: 20px !important;
    height: 50px !important;

    &::after {
      font-size: 0.75rem !important;
    }
  }
}

.timelineHistory .swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset, 0);
  left: auto;
}

.timelineHistory .swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: var(--swiper-navigation-sides-offset, 0);
  right: auto;
}

.nav-tabs {
  border-bottom: 1px solid  #B0BAC2;
  justify-content: space-between;
  @media (min-width: 576px) {


    max-width: 540px;

  }

  @media (min-width: 768px) {


    max-width: 720px;

  }

  @media (min-width: 992px) {


    max-width: 960px;

  }

  @media (min-width: 1200px) {


    max-width: 1140px;

  }

  @media (min-width: 1400px) {

    max-width: 1320px;

  }




  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  .nav-item {
    width: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    border: none;
    button {
    color: #878787;
      font-size: 1.4rem;
      border: none;
      &.active {
        border: none;
        border-bottom: 3px solid vars.$mainColor;
        font-weight: bold;
        color: vars.$mainColor;
      }
      &:focus-visible {
        outline: 0;
        box-shadow: none;
    }
    }
  }
}

/* ====================================== _____________ Reset Styles _____________ ====================================== */