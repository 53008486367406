/* ===  =================================== _____________ loading Styles _____________ ====================================== */
@use '../../../../styles/sass/utilities/variables' as vars;
@use '../../../../styles/sass/utilities/mixins' as mix;


// @mixin keyframes($name) {
//    @-o-keyframes #{$name} {
//       @content
//    }

//    ;

//    @-moz-keyframes #{$name} {
//       @content
//    }

//    ;

//    @-webkit-keyframes #{$name} {
//       @content
//    }

//    ;

//    @keyframes #{$name} {
//       @content
//    }

//    ;
// }

// @mixin animation($prop...) {
//    -o-animation: $prop;
//    -moz-animation: $prop;
//    -ms-animation: $prop;
//    -webkit-animation: $prop;
//    animation: $prop;
// }

// @mixin transform($prop...) {
//    -o-transform: $prop;
//    -moz-transform: $prop;
//    -webkit-transform: $prop;
//    -ms-transform: $prop;
//    transform: $prop;
// }

// @mixin transform-origin($prop...) {
//    -o-transform-origin: $prop;
//    -moz-transform-origin: $prop;
//    -webkit-transform-origin: $prop;
//    -ms-transform-origin: $prop;
//    transform-origin: $prop;
// }


// .loading {
//    width: 100%;
//    height: 100vh;
//    position: fixed;
//    top: 0;
//    left: 0;

//    &:before,
//    &:after,
//    &_dot,
//    &_outline {
//       position: absolute;
//       top: 50%;
//       left: 50%;
//       @include transform(translate(-50%, -50%));
//       border-radius: 50%;
//    }

//    &_dot {
//       width: #{120px - 120px / 100 * 20};
//       height: #{120px - 120px / 100 * 20};
//       background: #FFFFFF;
//       @include animation(in 4s linear infinite);
//       z-index: 2;
//    }

//    &:before {
//       content: "";
//       width: 0px;
//       height: 0px;
//       background: vars.$mainColor;
//       @include animation(out1 4s linear infinite);
//    }

//    &:after {
//       content: "";
//       width: 0px;
//       height: 0px;
//       background: #FFFFFF;
//       @include animation(out2 4s linear infinite);
//    }

//    &_outline {
//       width: 120px;
//       height: 120px;
//       z-index: 2;

//       span {
//          width: 120px / 2 + 8;
//          height: 120px / 2 + 8;
//          @include transform-origin(100% 100%);
//          @include transform(rotate(45deg) skewX(80deg));
//          overflow: hidden;
//          position: absolute;
//          bottom: 50%;
//          right: 50%;
//          @include animation(outline 4s linear infinite);

//          &:before {
//             content: "";
//             display: block;
//             border: solid 5px #fff;
//             width: 200%;
//             height: 200%;
//             border-radius: 50%;
//             @include transform(skewX(-80deg));
//             @include animation(outlineBefore 4s linear infinite);
//          }
//       }
//    }
// }

// @include keyframes(outline) {
//    0% {
//       @include transform(rotate(0deg) skewX(80deg));
//    }

//    25% {
//       @include transform(rotate(500deg) skewX(15deg));
//    }

//    50% {
//       @include transform(rotate(1000deg) skewX(40deg));
//    }

//    75% {
//       @include transform(rotate(1500deg) skewX(60deg));
//    }

//    100% {
//       @include transform(rotate(2160deg) skewX(80deg));
//    }
// }

// @include keyframes(outlineBefore) {
//    0% {
//       @include transform(skewX(-80deg));
//       border: solid 5px #ffffff;
//    }

//    25% {
//       @include transform(skewX(-15deg));
//       border: solid 5px #ffffff;
//    }

//    49% {
//       border: solid 5px #ffffff;
//    }

//    50% {
//       @include transform(skewX(-40deg));
//       border: solid 5px #3463EB;
//    }

//    75% {
//       @include transform(skewX(-60deg));
//       border: solid 5px #3463EB;
//    }

//    100% {
//       @include transform(skewX(-80deg));
//       border: solid 5px #3463EB;
//    }
// }

// @include keyframes(in) {
//    0% {
//       width: #{120px + 120px / 100 * 20};
//       height: #{120px + 120px / 100 * 20};
//       background: #FFFFFF;
//    }

//    40% {
//       width: 0px;
//       height: 0px;
//       background: #FFFFFF;
//    }

//    41% {
//       width: 0px;
//       height: 0px;
//       background: vars.$mainColor;
//    }

//    50% {
//       width: #{120px + 120px / 100 * 20};
//       height: #{120px + 120px / 100 * 20};
//       background: vars.$mainColor;
//    }

//    90% {
//       width: 0px;
//       height: 0px;
//       background: vars.$mainColor;
//    }

//    91% {
//       width: 0px;
//       height: 0px;
//       background: #FFFFFF;
//    }

//    100% {
//       width: #{120px + 120px / 100 * 20};
//       height: #{120px + 120px / 100 * 20};
//       background: #FFFFFF;
//    }
// }

// @include keyframes(out1) {
//    0% {
//       width: 0px;
//       height: 0px;
//    }

//    30% {
//       width: 120vw;
//       height: 120vw;
//    }

//    100% {
//       width: 120vw;
//       height: 120vw;
//    }
// }

// @include keyframes(out2) {
//    0% {
//       width: 0px;
//       height: 0px;
//    }

//    30% {
//       width: 0px;
//       height: 0px;
//    }

//    60% {
//       width: 120vw;
//       height: 120vw;
//    }

//    100% {
//       width: 120vw;
//       height: 120vw;
//    }
// }

/* Absolute Center Spinner */
.loading {
   position: fixed;
   z-index: 99999;
   height: 2em;
   width: 2em;
   overflow: visible;
   margin: auto;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
}

/* Transparent Overlay */
.loading:before {
   content: '';
   display: block;
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: vars.$mainColor;
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
   /* hide "loading..." text */
   font: 0/0 a;
   color: transparent;
   text-shadow: none;
   background-color: transparent;
   border: 0;
}

.loading:not(:required):after {
   content: '';
   display: block;
   font-size: 10px;
   width: 1em;
   height: 1em;
   margin-top: -0.5em;
   animation: spinner 1500ms infinite linear;
   border-radius: 0.5em;
   box-shadow: white 1.5em 0 0 0, white 1.1em 1.1em 0 0, white 0 1.5em 0 0, white -1.1em 1.1em 0 0, white -1.5em 0 0 0, white -1.1em -1.1em 0 0, white 0 -1.5em 0 0, white 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
   0% {
      transform: rotate(0deg);
   }

   100% {
      transform: rotate(360deg);
   }
}

@-moz-keyframes spinner {
   0% {
      transform: rotate(0deg);
   }

   100% {
      transform: rotate(360deg);
   }
}

@-o-keyframes spinner {
   0% {
      transform: rotate(0deg);
   }
   100% {
      transform: rotate(360deg);
   }
}

@keyframes spinner {
   0% {
      transform: rotate(0deg);
   }

   100% {
      transform: rotate(360deg);
   }
}


/* ===  =================================== _____________ loading Styles _____________ ====================================== */