/* ===  =================================== _____________ servicesSlider Styles _____________ ====================================== */
@use '../../../styles/sass/utilities/variables' as vars;
@use '../../../styles/sass/utilities/mixins' as mix;

.title {
   font-size: vars.$font-2500;
   color: vars.$mainColor;
   margin-bottom: 1.4rem;

   @media (max-width: 1200px) {
      margin-bottom: 1.5rem;
   }

   @include mix.respond(landscape2) {
      margin-bottom: 1.4rem;
   }
}

.servicesSlider {
   position: relative;
   &_overlay {
      transition: vars.$transition;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: -1;
      width: 100%;
      &_img {
         width: 100%;
         height: 100%;
         object-fit: cover;
         display: none;
         transition: vars.$transition;
         // &:first-child {
         //    display: block;
         // }

      }
   }

   &_slider {
      height: 554px;
   }

   &_slide {
      position: relative;
      &_img {
         width: 100%;
      }

      &_content {
         background-color: rgba(52, 61, 72, 0.3);
         position: absolute;
         left: 0;
         top: 0;
         width: 100%;
         height: 100%;

         transition: vars.$transition;

         &_title {
            color: white;
            font-size: vars.$font-1200;
            font-family: 'BMW Helvetica';
            position: absolute;
            bottom: 25px;
            width: 50%;
            transition: vars.$transition;
         }

         &:hover {
            background-color: rgba(52, 61, 72, 0.5);

            .servicesSlider_slide_content_title {
               bottom: 50px;
            }
         }
      }
   }

}

.servicesSlider_slideEn {
   .servicesSlider_slide_content_title {
      left: 35px;
   }
}

.servicesSlider_slideAr {
   .servicesSlider_slide_content_title {
      right: 35px;
      left: auto;
      font-weight: 700;
   }
}

/* ===  =================================== _____________ servicesSlider Styles _____________ ====================================== */