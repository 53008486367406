/* ===  =================================== _____________ header Styles _____________ ====================================== */
@use '../../../styles/sass/utilities/variables' as vars;
@use '../../../styles/sass/utilities/mixins' as mix;

.header {
    width: 93%;
    background-color: #FFFFFF;
    margin: 10px auto;
    padding: 0 50px;
    border-radius: 23px;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 99999999;

    &_logo {
        width: 50px;
        margin-inline-end: 15px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    @media (max-width: 1300px) {
        padding: 0 40px;
    }

    &_list {
        display: flex;
        gap: 25px;
        margin-bottom: 0;
        padding: 0;

        @media (max-width: 1300px) {
            gap: 20px;
        }

        &_item {
            font-size: vars.$font-1000;
            text-transform: capitalize;
            font-weight: 500;
            transition: vars.$transition;
            color: black;
            position: relative;
            width: max-content;
            cursor: pointer;

            &:hover {
                color: vars.$mainColor;
                transition: vars.$transition;
            }

            @include mix.respond(medium2) {
                margin-top: 5px;
                margin-bottom: 5px;

                &:first-child {
                    margin-top: 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        @include mix.respond(medium2) {
            display: block;
            margin-top: 11px;
            margin-bottom: 11px;
        }
    }

    &_switch {
        margin-inline-start: 0.5rem;
        margin-inline-end: 1rem;
        // @include mix.respond(medium2) {
        //     margin: 0;
        //     margin-inline-end: 10px;
        //     padding-inline-start: 0;
        // }
    }

    @media (max-width: 1200px) {
        padding: 8px 20px;
        border-radius: 18px;

        &_logo {
            width: 40px;
        }

        &_list {
            gap: 15px;
        }
    }

    &_search {
        cursor: pointer;
    }
}

/* ===  =================================== _____________ header Styles _____________ ====================================== */