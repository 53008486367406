/* ===  =================================== _____________ mainLinks Styles _____________ ====================================== */
@use '../../../styles/sass/utilities/variables'as vars;
@use '../../../styles/sass/utilities/mixins'as mix;

// header link
.mainLink {
   background-color:  vars.$mainColor;
   color: white;
   border-radius: 5px;
   padding: 10px 23px;
   font-size: vars.$font-800;
   text-transform: capitalize;
   box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
   display: inline-block;
   transition: vars.$transition;
   &:hover {
    color: white;
    background-color: vars.$lightBlueColor;
  }
}

/* ===  =================================== _____________ mainLinks Styles _____________ ====================================== */
