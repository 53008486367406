/* ===  =================================== _____________ mainBanner Styles _____________ ====================================== */
@use '../../styles/sass/utilities/variables' as vars;
@use '../../styles/sass/utilities/mixins' as mix;
.mainBanner {
    height: 115vh;
    position: relative;
    padding-bottom: 0;
    &_img {
        height: 100%;
        object-fit: cover;
        width: 100%;
        object-position: top;
    }
    &_video {
        object-fit: cover;
    }
    &:before {
        content: "";
        top: 0;
        left: 0;
        z-index: 2;
        background: rgb(62 62 62 / 54%);
        background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 44%) 45%, rgb(0 0 0 / 77%) 100%);
        width: 100%;
        height: 100%;
        position: absolute;
    }
    &_content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        position: absolute;
        text-align: center;
        width: 60%;
        margin: 0 auto;
        gap: 30px;
        z-index: 3;
        left: 50%;
        transform: translate(-50%, -53%);
        top: 56%;
        &_title {
            font-size: vars.$font-3200;
            margin: 0;
            color: vars.$white;
            font-weight: bold;
            font-family: 'HelveticaNeueLT Arabic 55 Roman';
        }
        &_subTitle, &_subTitle *  {
            margin: 0;
            font-size: vars.$font-900;
            color: vars.$white;
            font-weight: 300;
            font-family: 'Helvetica Neue LT Pro';
            opacity: 0.9;
      
        }
        &_subTitle {

            width: 60%;
            margin: 0 auto;
            @include mix.respond(medium2) {
                width: 80%;
            }
            @include mix.respond(small) {
                width: 100%;
            }
        }
        &_subTitle * {
            width: 100%;

        }
        @media (max-width: 1200px) {
            width: 75%;
        }
        @include mix.respond(medium2) {
            width: 90%;
        }
        @include mix.respond(small) {
            width: 100%;
            padding: 0 15px;
        }
    }
    
}
/* ===  =================================== _____________ mainBanner Styles _____________ ====================================== */
