/* ===  =================================== _____________ Global Styles _____________ ====================================== */
@use '../utilities/variables'as vars;
@use '../utilities/mixins'as mix;

html {
  scroll-behavior: smooth;
}
body {
  overflow-x: hidden;
}

// == Shimiring //
.shimiring {
  animation: shimmer 2s infinite;
  overflow: hidden;
  @include mix.animation(Shimiring 2s infinite);
  background     : linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
}

// == Shimiring //

// == not found //
.notfound {
  padding        : 0;
  display        : flex;
  justify-content: center;
  align-items    : center;
  width          : 100vw;
  height         : 80vh;
}


section {
  padding-bottom: 55px;
  @include mix.respond(landscape2) {
     padding: 0 0 50px 0;
  }
}

.timeline {
  .swiper-slide.swiper-slide-prev {
    opacity: 50%;
    transform: translateY(50px) !important;
    transition: vars.$sl-transition;
  }
}
/* ===  =================================== _____________ Global Styles _____________ ====================================== */