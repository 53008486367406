/* ===  =================================== _____________ contactForm Styles _____________ ====================================== */
@use '../../../../styles/sass/utilities/variables' as vars;
@use '../../../../styles/sass/utilities/mixins' as mix;

.contactForm {
   background-color: white;
   padding: 2rem;
   border-radius: 20px;
   &_title {
      font-family: "BMW Helvetica";
      color: vars.$textColor;
      font-weight: bold;
      margin-bottom: 2rem;
      font-size: 2rem;
   }
   &_input {
      padding: 10px 20px;
      border-radius: 10px;
      border: 1px solid #D9D9D9;
      font-size: 1rem;
      width: 100%;
      color: #C4C4C4;
      &::placeholder {
         font-size: 1.2rem;
         color: #C4C4C4;
      }
   }
   
   &_textarea {
      resize: none;
      height: 190px;
   }
   
}

/* ===  =================================== _____________ contactForm Styles _____________ ====================================== */