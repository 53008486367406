/* ===  =================================== _____________ numberBox Styles _____________ ====================================== */
@use '../../../styles/sass/utilities/variables' as vars;
@use '../../../styles/sass/utilities/mixins' as mix;

.numberBox {
   background-color: rgba(217,217,217, 10%);
    text-align: center;
   border-radius: 20px;
   border: 1px solid rgba(40, 59, 138, 0.1);
   height: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding: 1rem 2rem;
   transition: vars.$fs-transition;
   &:hover {
      background-color: vars.$white;
      box-shadow: 0px 5px 15px 5px rgba(0, 0, 0, 0.04);
   }
   &_img {
      width: 65px;
      height: 65px;
      object-fit: contain;
      margin-bottom: 0.5rem;
   }
   &_title {
      color: vars.$mainColor;
      font-size: vars.$font-1500;
      font-family: 'BMW Helvetica';
      font-weight: bold;
   }
   &_desc {
      color: vars.$textColor;
   }
}



/* ===  =================================== _____________ numberBox Styles _____________ ====================================== */