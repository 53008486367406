/* ===  =================================== _____________ secondCard Styles _____________ ====================================== */
@use '../../../styles/sass/utilities/variables' as vars;
@use '../../../styles/sass/utilities/mixins' as mix;

.secondCard {
   // border-radius: 20px;
   overflow: hidden;
   // box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.08);
   height: 100%;
   &_content { 
      padding: 1.5rem;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: flex-start;
      background: #f4f4f4;
      min-height: calc(100% - 320px);
      @media (max-width: 1200px) {
         min-height: calc(100% - 230px);

      }
      @media (max-width: 991px) {
         min-height: calc(100% - 260px);

      }
      @media (max-width: 768px) {
         min-height: auto;
      }
      &_title {
         color: black;
         font-size: vars.$font-1500;
      }

      &_desc {
         margin-bottom: 1.5rem;
         color: vars.$textColor;
         font-size: vars.$font-1000;
         font-weight: 500;
      }
   }
}



/* ===  =================================== _____________ secondCard Styles _____________ ====================================== */