/* ===  =================================== _____________ contctUsSection Styles _____________ ====================================== */
@use '../../styles/sass/utilities/variables' as vars;
@use '../../styles/sass/utilities/mixins' as mix;


.contctUsSection {
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 3.5rem;
   gap: 4rem;
   background: rgb(27, 186, 226);
   // background: linear-gradient(270deg, rgba(3, 66, 237, 1) 0%,  rgba(34, 186, 254, 1) 100%);

   @media (max-width: 1200px) {
      gap: 3rem;
   }

   @include mix.respond(medium2) {
      flex-direction: column;
      gap: 1rem;
      justify-content: center;
      align-items: center;
      text-align: center;
   }

   &_desc,
   &_phoneNumber,
   &_link {
      color: white;
      font-size: vars.$font-1400;
      margin-bottom: 0;

   }

   &.orange {
      @include mix.respond(medium2) {
         text-align: start;
      }

      padding-inline-start: 8rem;
      background: rgb(27, 186, 226);
      // background: linear-gradient(270deg, rgba(34, 186, 254, 1) 0%, rgba(3, 66, 237, 1) 100%);
      background: linear-gradient(270deg, rgba(27, 186, 226, 1) 0%);

      @include mix.respond(medium2) {
         text-align: start;
         padding-inline-start: 2rem;

      }
   }

   &.green {
      @include mix.respond(medium2) {
         text-align: start;
      }

      padding-inline-start: 8rem;
      background: rgb(13, 188, 236);
      // background: linear-gradient(90deg, rgba(13, 188, 236, 1) 0%, rgba(162, 238, 12, 1) 100%);

      @include mix.respond(medium2) {
         text-align: start;
         padding-inline-start: 2rem;

      }
   }
   &.darkGreen {
      @include mix.respond(medium2) {
         text-align: start;
      }

      padding-inline-start: 8rem;
      background: rgb(13, 188, 236);
      // background: linear-gradient(90deg, rgba(3, 220, 198, 1) 0%, rgba(36, 63, 132, 1) 100%);

      @include mix.respond(medium2) {
         text-align: start;
         padding-inline-start: 2rem;

      }
   }

   &_phoneNumber,
   &_link {
      &:hover {
         color: white;
      }
   }

   &_link {
      text-decoration: underline;
      font-weight: bold;
      font-family: 'BMW Helvetica';

   }
   &.cust {
      .contctUsSection_desc {
         width: 66%;
         display: flex;
         @media (max-width: 991px) {
            width: 100%;
         }
      }
   }
   &_logo {
      width: 150px;
      height: 100%;
   }
}




/* ===  =================================== _____________ contctUsSection Styles _____________ ====================================== */