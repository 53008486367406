/* ====================================== _____________ Mixin Styles _____________ ====================================== */

@use './variables' as vars;

//prefixer mixin //
@mixin prefixer($property, $value, $prefixes: ()) {
  @each $prefix in $prefixes {
    #{'-' + $prefix + '-' + $property}: $value;
  }

  #{$property}: $value;
}

//prefixer mixin //

//overlay mixin //
@mixin overlay {
  position: relative;

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: vars.$black, $alpha: 0.2);
    z-index: 2;
  }
}

//overlay mixin //

//Hover mixin //
@mixin navHover {
  transition: vars.$transition;
  position: relative;
  padding-bottom: 5px;

  &::before {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    width: 0;
    height: 1px;
    background-color: vars.$mainColor;
    body.ar & {
      right: 0;
      left: auto;
    }
  }

  &:hover {
    width: 100%;
  }
}

//Hover mixin //

//Dropdown mixin //
@mixin dropdown {
  position: absolute;
  left: -15px;
  transform: scaleY(0);
  top: 119%;
  border-radius: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  min-width: 11.25rem;
  width: 11.25rem;
  color: #4d4033;
  transition: all 0.3s ease-in-out;
  transform-origin: top;
  background-color: #fff;
  z-index: 202;

  @media (max-width: 767px) {
    left: 0;
    transform: scaleY(0);

    &::before {
      top: 100%;
    }
  }
}
@mixin dropdownAr {
  position: absolute;
  right: -15px;
  transform: scaleY(0);
  top: 119%;
  border-radius: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  min-width: 11.25rem;
  width: 11.25rem;
  color: #4d4033;
  transition: all 0.3s ease-in-out;
  transform-origin: top;
  background-color: #fff;
  z-index: 202;

  @media (max-width: 767px) {
    right: 0;
    transform: scaleY(0);

    &::before {
      top: 100%;
    }
  }
}
@mixin dropdownItem {
  font-size: vars.$font-1100;
  transition: vars.$transition;
  text-transform: capitalize;
  padding: 0.5rem 1rem;
  @media (max-width: 768px) {
    padding: 0.5rem;
  }
}

@mixin aDropdown {
  position: relative;
  z-index: 202;

  @media (max-width: 767px) {
    &::before {
      position: absolute;
      content: '';
      right: .5rem;
      top: calc(52% - 5px);
      transform: translateY(-50%);
      width: 13px;
      height: 6.5px;
      background-image: url(/imgs/global/arrow-down.svg);
      background-repeat: no-repeat;
      background-size: 12px 6px;
      transition: vars.$transition;
      z-index: 1;
      body.ar & {
        right: auto !important;
        left: 0.5rem;
      }
    }
  }

  &:hover {
    &::before {
      background-image: url(/imgs/global/orange-arrow-down.svg);
      transform: translateY(-50%) rotate(-90deg);
    }
  }
}
@mixin aDropdownAr {
  position: relative;
  z-index: 202;

  @media (max-width: 767px) {
    &::before {
      position: absolute;
      content: '';
      right: .5rem;
      top: calc(52% - 5px);
      transform: translateY(-50%);
      width: 13px;
      height: 6.5px;
      background-image: url(/imgs/global/arrow-down.svg);
      background-repeat: no-repeat;
      background-size: 12px 6px;
      transition: vars.$transition;
      z-index: 1;
      body.ar & {
        right: auto !important;
        left: 0.5rem;
      }
    }
  }

  &:hover {
    &::before {
      background-image: url(/imgs/global/orange-arrow-down.svg);
      transform: translateY(-50%) rotate(90deg);
    }
  }
}
//Dropdown mixin //

// animation mixin //
@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }

  @-moz-keyframes #{$animation-name} {
    @content;
  }

  @-ms-keyframes #{$animation-name} {
    @content;
  }

  @-o-keyframes #{$animation-name} {
    @content;
  }

  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@include keyframes(Shimiring) {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

// Usage _____
// @include keyframes(slide-down) {
//     0% { opacity: 1; }
//     90% { opacity: 0; }
// }

// .element {
//     width: 100px;
//     height: 100px;
//     background: black;
//     @include animation('slide-down 5s 3');
// }

// animation mixin //

// Media  //
@mixin respond($breakpoint) {
  @if $breakpoint ==small {
    @media (max-width: 576px) {
      @content;
    }
  }

  @if $breakpoint ==landscape {
    @media (min-width: 576px) and (max-width: 768px) {
      @content;
    }
  }

  @if $breakpoint ==landscape2 {
    @media (max-width: 767px) {
      @content;
    }
  }

  @if $breakpoint ==medium {
    @media (min-width: 768px) and (max-width: 992px) {
      @content;
    }
  }

  @if $breakpoint ==medium2 {
    @media (max-width: 992px) {
      @content;
    }
  }

  @if $breakpoint ==large {
    @media (min-width: 992px) and (max-width: 1200px) {
      @content;
    }
  }

  @if $breakpoint ==large2 {
    @media (min-width: 992px) {
      @content;
    }
  }

  @if $breakpoint ==xLarge {
    @media (min-width: 1200px) and (max-width: 1400px) {
      @content;
    }
  }

  @if $breakpoint ==xLarge2 {
    @media (min-width: 1200px) {
      @content;
    }
  }

  @if $breakpoint ==xxLarge {
    @media (min-width: 1400px) {
      @content;
    }
  }
}


/* ====================================== _____________ Mixin Styles _____________ ====================================== */