/* ===  =================================== _____________ loading Styles _____________ ====================================== */
@use '../../../../styles/sass/utilities/variables' as vars;
@use '../../../../styles/sass/utilities/mixins' as mix;

.error {
   width: 100%;
   height: 100vh;
   position: fixed;
   top: 0;
   left: 0;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: white;
   z-index: 9;
   &_title {
      color: red;
      font-size: 4rem;
      font-weight: bold;
      font-family: "HelveticaLT";
   }

}



/* ===  =================================== _____________ loading Styles _____________ ====================================== */